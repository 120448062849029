import mapboxgl from 'mapbox-gl'
import MapboxDraw from '@mapbox/mapbox-gl-draw'

import { token, baseLayers } from '@/constants/mapbox'
import { ModesController } from '@/libs/map-draw/modes/modes-controller'
import { oddEditorStyles } from '@/libs/map-draw/config'

import {
  ModelController,
  BaseController,
  EditorController
} from '../controllers'
import { loadIcons } from '../helpers'

const addMapControls = (map, place) => {
  map.addControl(
    new mapboxgl.GeolocateControl({
      positionOptions: {
        enableHighAccuracy: true
      },
      trackUserLocation: true
    }),
    place
  )
  map.addControl(new mapboxgl.NavigationControl({ showCompass: false }), place)
}

export const initMap = async component => {
  const { state } = component.$store
  const { baseLayer, center, zoom } = state.initialState
  const style = baseLayers.find(layer => layer.name === baseLayer).link

  mapboxgl.accessToken = token
  component.mapgl = new mapboxgl.Map({
    container: 'map',
    style,
    center,
    zoom
  })

  const { controllers, mapgl } = component

  mapgl.touchPitch.disable()
  mapgl.dragRotate.disable()

  addMapControls(mapgl, 'bottom-right')

  // controllers

  mapgl.once('load', async () => {
    component.$store.commit('LOG', 'Map was loaded')
    controllers.model = new ModelController(component)
    controllers.base = new BaseController(component)

    await controllers.model.loadModel()
    component.$store.commit('LOG', 'loadModel() completed')

    component.$store.commit('LOG', 'addBaseLayers() starts')
    await controllers.base.addBaseLayers()
    component.$store.commit('LOG', 'Layers was added')

    await loadIcons(component, component.$store.state.books.signIcons)

    // map draw init
    const mc = new ModesController(component, MapboxDraw, 'editorState')
    component.draw = new MapboxDraw({
      displayControlsDefault: false,
      styles: oddEditorStyles,
      userProperties: true,
      modes: Object.assign(MapboxDraw.modes, {
        simple_select: mc.createODDSimpleSelectMode(),
        direct_select: mc.createODDDirectSelectMode(),
        draw_signs: mc.createODDDrawSignsMode(),
        draw_points: mc.createODDDrawPointsMode(),
        draw_lines: mc.createODDDrawLinesMode()
      })
    })

    controllers.editor = new EditorController(component)
  })
}
