import {
  // odd
  createODDSimpleSelectMode,
  createODDDirectSelectMode,
  createODDDrawSignsMode,
  createODDDrawPointsMode,
  createODDDrawLinesMode
} from './creators'

export class ModesController {
  constructor (map, MapboxDraw, type) {
    this.map = map
    this.MapboxDraw = MapboxDraw
    this.mapgl = map.map
    this.state = map[type]
  }

  // event editor modes
  createODDSimpleSelectMode () {
    return createODDSimpleSelectMode(this.MapboxDraw, this.map)
  }

  createODDDirectSelectMode () {
    return createODDDirectSelectMode(this.MapboxDraw, this.map)
  }

  createODDDrawSignsMode () {
    return createODDDrawSignsMode(this.MapboxDraw, this.map)
  }

  createODDDrawPointsMode () {
    return createODDDrawPointsMode(this.MapboxDraw, this.map)
  }

  createODDDrawLinesMode () {
    return createODDDrawLinesMode(this.MapboxDraw, this.map)
  }
}
