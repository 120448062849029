import cloneDeep from 'lodash.clonedeep'
// import throttle from 'lodash.throttle'
import {
  CommonSelectors,
  Constants,
  doubleClickZoom,
  createSupplementaryPoints
} from '@/libs/map-draw/lib-utils'

export const createODDDirectSelectMode = (MapboxDraw, map) => {
  const DirectSelect = {}
  const { editorState } = map.$store.state

  DirectSelect.onSetup = function (opts) {
    const featureId = opts.featureId
    const feature = this.getFeature(featureId)

    if (!feature) {
      throw new Error(
        'You must provide a featureId to enter direct_select mode'
      )
    }

    if (feature.type === Constants.geojsonTypes.POINT) {
      throw new TypeError("direct_select mode doesn't handle point features")
    }

    const line_route_items = feature.coordinates.map((p, index) => ({
      geom: {
        coordinates: p
      },
      stop_point_id: index
    }))

    const state = {
      featureId,
      feature,
      dragMoveLocation: opts.startPos || null,
      dragMoving: false,
      canDragMove: false,
      selectedCoordPaths: opts.coordPath ? [opts.coordPath] : [],
      lineRouteItems: line_route_items,
      waypoints: [],
      prevLineRouteItems: cloneDeep(line_route_items),
      draggingPointIndex: 0,
      currentCoordinates: [],
      prevCoordinates: feature.getCoordinates(),
      currentPoint: null
    }

    this.setSelectedCoordinates(
      this.pathsToCoordinates(featureId, state.selectedCoordPaths)
    )
    this.setSelected(featureId)
    doubleClickZoom.disable(this)

    this.setActionableState({
      trash: true
    })

    return state
  }

  DirectSelect.onMouseMove = function (state, e) {
    // On mousemove that is not a drag, stop vertex movement.
    const isFeature = CommonSelectors.isActiveFeature(e)
    const onVertex = CommonSelectors.isVertex(e)
    const noCoords = state.selectedCoordPaths.length === 0
    if (isFeature && noCoords) {
      this.updateUIClasses({ mouse: Constants.cursors.POINTER })
    } else if (onVertex && !noCoords) {
      this.updateUIClasses({ mouse: Constants.cursors.POINTER })
    } else this.updateUIClasses({ mouse: Constants.cursors.NONE })

    if (editorState.type !== 'lines') this.stopDragging(state)
  }

  DirectSelect.toDisplayFeatures = function (state, geojson, push) {
    if (state.featureId === geojson.properties.id) {
      geojson.properties.active = 'true'
      push(geojson)

      const supplementaryPoints = createSupplementaryPoints(geojson, {
        map: this.map,
        midpoints: true,
        selectedPaths: state.selectedCoordPaths
      })

      supplementaryPoints.forEach(push)
    } else {
      geojson.properties.active = 'false'
      push(geojson)
    }
    this.fireActionable(state)
  }

  return { ...MapboxDraw.modes.direct_select, ...DirectSelect }
}
