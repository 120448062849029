<template>
  <div class="comments__send-comment">
    <el-input
      v-model="comment"
      type="textarea"
      :rows="2"
      placeholder="Введите комментарий"
      name="comment"
      maxlength="140"
      @keyup.enter.native="sendComment" />
    <r-button
      type="primary"
      for="comment"
      icon="send-msg"
      @click="sendComment" />
  </div>
</template>

<script>
import { errorParser } from '@/utils'

export default {
  props: {
    source_id: {
      type: String,
      default: null
    },
    id: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      comment: ''
    }
  },
  methods: {
    async sendComment () {
      if (!this.id || !this.source_id) return
      if (!this.comment.length) return

      const url = `objectComments/${this.source_id}/${this.id}?body=${this.comment}`
      try {
        await this.$store.dispatch('POST_REQUEST', { url })
        this.comment = ''
        this.$emit('load')
      } catch (error) {
        errorParser.call(this, error)
      }
    }
  }
}
</script>

<style lang="scss">
.comments {
  &__send-comment {
    display: grid;
    justify-content: stretch;
    align-items: end;
    grid-auto-flow: column;
    grid-gap: 0.5rem;
    grid-template-columns: auto 2.25rem;

    .el-textarea {
      border-radius: var(--border-radius);

      &__inner {
        padding: 0.25rem 0.5rem;
        color: var(--text_primary);
        background-color: var(--field_bg);
        border: 1px solid var(--field_border);
      }
    }
  }
}
</style>
