<template>
  <div
    v-loading="isLoading"
    class="bdd-checklist-list">
    <r-title>Транспортные средства</r-title>
    <r-search-input
      :filter-text="filterText"
      @change="searchChange" />
    <ul
      v-if="filteredChecklist.length"
      class="bdd-checklist-list__wrapper">
      <li
        v-for="checklist in filteredChecklist"
        :key="checklist.id"
        class="bdd-checklist-list__item"
        @click="goToCheckList(checklist.id)">
        <div class="bdd-checklist-list__item-col">
          <r-title
            type="title-2"
            color-type="primary">
            {{ checklist.reg_number }}
          </r-title>
          <r-text color-type="secondary">
            {{ `Плановая дата осмотра: ${checklist.inspection_dt || '-'}` }}
          </r-text>
          <r-text color-type="secondary">
            {{ `Фактическая дата осмотра: ${checklist.inspection_dt_fact || '-'}` }}
          </r-text>
        </div>
        <div class="bdd-checklist-list__item-col">
          <r-icon
            name="chevron-right"
            :size="24" />
        </div>
      </li>
    </ul>
    <div v-else>
      {{ filterText.trim() ? 'Чек-лист не найден' : 'Нет чек-листов' }}
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      isLoading: false,
      checklistsUrl: this.$store.state.bdd.checklistsUrl,
      checklists: [],
      filterText: ''
    }
  },
  computed: {
    filteredChecklist () {
      if (!this.filterText.trim()) return this.checklists || []
      return (
        this.checklists.filter(e =>
          e.reg_number
            ?.trim()
            ?.toLowerCase()
            .includes(this.filterText?.trim()?.toLowerCase())
        ) || []
      )
    }
  },
  created () {
    this.getChecklists()
  },
  methods: {
    searchChange (e) {
      this.filterText = e
    },
    async getChecklists () {
      this.isLoading = true
      const config = {
        include: {
          application: {
            include: {
              vehicle: {
                only: ['reg_number']
              },
              status: {}
            }
          }
        }
      }

      try {
        const { data } = await this.$store.dispatch('GET_REQUEST', {
          url: `objectInfo/${this.checklistsUrl}?config=${JSON.stringify(
            config
          )}`
        })
        this.checklists = this.getChecklistData(data)

        this.isLoading = false
      } catch (e) {
        console.log(e)
        this.isLoading = false
      }
    },
    goToCheckList (id) {
      this.$router.push(`/bdd-checklists?id=${id}`)
    },
    getChecklistData (data) {
      const ids = {}

      return Object.values(data)
        .sort((a, b) => {
          if (a.created_at > b.created_at) return -1
          if (a.created_at < b.created_at) return 1
          return 0
        })
        .filter(e => {
          if (!ids[e.application_id]) {
            ids[e.application_id] = {}
            return (
              e.application_id &&
              (e.application?.status?.name === 'Назначен осмотр' ||
                e.application?.status?.name ===
                  'Проведён осмотр, есть нарушения, назначен повторный осмотр')
            )
          }
        })
        .map(k => {
          k.reg_number = k.application?.vehicle?.reg_number
          k.inspection_dt = k?.application?.inspection_dt
            ? this.$rDate.format(k?.application?.inspection_dt)
            : ''
          k.created_at = this.$rDate.format(k.created_at)

          return k
        })
        .sort((a, b) => {
          if (a.inspection_dt > b.inspection_dt) return 1
          if (a.inspection_dt < b.inspection_dt) return -1
          return 0
        })
    }
  }
}
</script>

<style lang="scss">
.bdd-checklist-list {
  position: relative;
  padding: 1rem;
  display: grid;
  grid-gap: 0.75rem;
  width: 100%;
  max-width: 800px;
  align-content: flex-start;

  &__wrapper {
    width: 100%;
    overflow: auto;

    > * {
      margin-bottom: 4px;
      position: relative;
      border-bottom: 1px solid;
      border-color: var(--dividers_low_contrast) !important;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__item {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 4px 0;

    &-col {
      > * {
        &:nth-child(2) {
          margin-top: 4px;
        }
      }
    }
  }
}
</style>
