<template>
  <div class="create-window">
    <r-bottom-sheet
      v-show="step === 0 || step === 2"
      ref="create"
      :overlay="true"
      class="create-card"
      :max-height="cardMaxHeight"
      :title="cardTitle"
      close-button
      @closed="close">
      <create-type-select v-if="step === 0" />
      <new-object-card
        v-else-if="step === 2"
        @close="close" />
    </r-bottom-sheet>

    <create-map-poptip v-if="step === 1" />
  </div>
</template>

<script>
import CreateTypeSelect from '@/modules/odd/editor/create-type-select'
import CreateMapPoptip from '@/modules/odd/editor/create-map-poptip'
import NewObjectCard from '@/modules/odd/editor/new-object-card'

export default {
  components: {
    CreateTypeSelect,
    NewObjectCard,
    CreateMapPoptip
  },
  computed: {
    step () {
      return this.$store.state.editorState.step
    },
    cardMaxHeight () {
      if (this.step === 2) return '560px'

      return '366px'
    },
    cardTitle () {
      if (this.step === 2) {
        return 'Заполните информацию'
      }

      return 'Выберите тип объекта'
    }
  },
  mounted () {
    this.$refs.create.open()
  },
  methods: {
    close () {
      if (this.$route.name !== 'odd') this.$router.push('/odd')
    }
  }
}
</script>
