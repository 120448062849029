<template>
  <div class="bdd">
    <bdd-checklists />
    <navbar />
  </div>
</template>

<script>
import BddChecklists from '@/modules/bdd/bdd-checklists/bdd-checklists'
import navbar from '@/modules/bdd/bdd-checklists/navbar/navbar'

import { toggleBodyTheme } from '@/utils'

export default {
  components: { navbar, BddChecklists },
  created () {
    toggleBodyTheme('light')
  }
}
</script>

<style lang="scss" scoped>
.bdd {
  width: 100%;
  position: relative;
  height: 100%;
  overflow: auto;
}
</style>
