<template>
  <div class="inspection-list-item">
    <div class="inspection-list-item__label">
      <el-popover
        v-if="tooltip"
        popper-class="checklist-popover"
        placement="right"
        width="500"
        trigger="hover">
        <ul>
          <li
            v-for="(tip, i) in tooltip.tooltip"
            :key="i">
            <r-title type="title-2">
              {{ tip }}
            </r-title>
          </li>
        </ul>
        <r-icon
          slot="reference"
          name="help"
          :size="24" />
      </el-popover>
      <r-title
        type="title-2"
        color-type="subhead">
        {{ label }}
      </r-title>
    </div>
    <div class="inspection-list-item__controls">
      <el-radio
        v-model="item.value"
        class="r-radio"
        :label="true" />
      <el-radio
        v-model="item.value"
        class="r-radio error"
        :label="false" />
    </div>
    <div
      class="inspection-list-item__name"
      :class="
        (!alwaysShowButton && item.value) ||
          alwaysShowButton ||
          longComment(item)
          ? 'full-row'
          : ''
      ">
      <div v-if="hasDocs" class="inspection-list-item__col">
        <r-text color-type="secondary">
          № Документа
        </r-text>
        <el-input
          v-if="parent"
          v-model="source[parent][attr_name]"
          clearable
          class="r-input" />
        <el-input
          v-else
          v-model="source[attr_name]"
          clearable
          class="r-input" />
      </div>
      <div class="inspection-list-item__col">
        <r-text color-type="secondary">
          Примечание
        </r-text>
        <el-input
          v-model="item.comment"
          class="r-input"
          placeholder="Введите текст" />
      </div>
    </div>
    <checklist-files
      v-if="alwaysShowButton || !item.value"
      class="inspection-list-item__attachments"
      :attr-id="item.id"
      :attr-name="label" />
  </div>
</template>

<script>
import checklistFiles from '../checklist-files/checklist-files'
import { tooltips } from '../../../../configs'

export default {
  components: { checklistFiles },
  props: {
    source: {
      type: Object,
      required: true
    },
    item: {
      type: Object,
      required: true,
      default: () => null
    },
    hasDocs: {
      type: Boolean,
      default: false
    },
    docs: {
      type: Array,
      required: true
    },
    alwaysShowButton: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      tooltips
    }
  },
  computed: {
    label () {
      return this.item.label
    },
    tooltip () {
      return this.tooltips.find(({ label }) => label === this.label) || null
    },
    doc () {
      return this.docs?.find(({ title }) => title === this.label)
    },
    parent () {
      return this.doc?.parent
    },
    attr_name () {
      return this.doc?.attr_name
    }
  },
  methods: {
    longComment (item) {
      return item?.comment?.length >= 40
    }
  }
}
</script>

<style lang="scss">
.inspection-list-item {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr 1fr;
  padding-bottom: 1rem;
  align-items: end;
  border-bottom: 1px solid var(--field_border);

  &:last-child {
    border-bottom: none;
  }

  &__label {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 0.5rem;
    justify-content: start;
  }

  &__name {
    display: grid;
    grid-gap: 1rem;
    align-items: start;
    grid-auto-flow: column;
  }

  &__attachments {
    display: grid;
    justify-content: start;
    align-items: end;

    .r-button {
      height: 39px;
    }
  }

  &__controls {
    display: grid;
    grid-gap: 0.5rem;
    align-items: center;
    grid-auto-flow: column;
    justify-content: center;
  }

  .r-radio {
    margin: 0;
    width: 6rem;
    display: flex;
    justify-content: center;

    .el-radio__input {
      .el-radio__inner {
        width: 20px;
        height: 20px;
        border: 2px solid;
        border-color: var(--dividers_low_contrast) !important;
        background-color: var(--white_white) !important;
      }
    }
    .el-radio__label {
      display: none;
    }
    &.is-checked {
      .el-radio__input {
        .el-radio__inner {
          border-color: var(--accent_primary) !important;
          position: relative;

          &:after {
            content: '';
            width: 14px;
            height: 14px;

            background-color: var(--accent_primary);
          }
        }
      }
    }

    &.error {
      .el-radio__input {
        .el-radio__inner {
          border-color: var(--accent_error_light) !important;
        }
      }
      &.is-checked {
        .el-radio__input {
          .el-radio__inner {
            &:after {
              background-color: var(--accent_error);
            }
          }
        }
      }
    }
  }
}

.full-row {
  grid-column: 1 / 3;
}

.checklist-popover {
  ul {
    li {
      position: relative;
      &:before {
        content: '•';
        display: block;
        position: absolute;
        top: 3px;
        left: 5px;
        color: var(--text_primary);
      }
      .r-title {
        text-indent: 20px;
        word-break: normal;
        text-align: start;
      }
    }
  }
}
</style>
