<template>
  <div class="interval-filter">
    <div class="popper-filter__content">
      <div class="interval-filter__item">
        <r-text type="caption">
          Начало
        </r-text>
        <el-date-picker
          v-model="interval.from"
          class="r-date-picker"
          size="mini"
          name="datetime"
          :type="pickerType"
          :format="format" />
      </div>

      <div class="interval-filter__item">
        <r-text type="caption">
          Окончание
        </r-text>
        <el-date-picker
          v-model="interval.to"
          class="r-date-picker"
          size="mini"
          name="datetime"
          :type="pickerType"
          :format="format" />
      </div>
    </div>

    <div class="interval-filter__controls popper-filter__controls">
      <r-button
        type="primary"
        @click="prepareData">
        Применить
      </r-button>
      <r-button
        simple
        :disabled="isResetDisabled"
        @click="resetFilter">
        Сброс
      </r-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    filterValue: {
      type: Object,
      required: true
    },
    pickerType: {
      type: String,
      default: 'date'
    },
    format: {
      type: String,
      default: 'dd.MM.yyyy'
    },
    id: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      ...this.filterValue
    }
  },
  computed: {
    isResetDisabled () {
      const { to, from } = this.interval
      return !(from || to)
    }
  },
  methods: {
    resetFilter () {
      this.interval.from = this.interval.to = null
      this.prepareData()
    },
    prepareData () {
      const { interval, id } = this

      const data = {
        type: 'interval-filter',
        prop: { interval },
        id: id,
        active: !!(interval.from || interval.to)
      }

      this.$emit('load', data)
    }
  }
}
</script>

<style lang="scss">
.interval-filter {
  display: grid;
  grid-auto-flow: row;
  grid-gap: 1rem;
  align-items: center;
  justify-content: center;

  &__controls {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 0.5rem;
    align-items: center;
    justify-content: space-between;
  }
}
</style>

<i18n>
{
  "ru": {
    "interval-filter:start": "Начало",
    "interval-filter:end": "Окончание",
    "interval-filter:accept": "Применить",
    "interval-filter:reset": "Сброс"
  },
  "en": {
    "interval-filter:start": "Start",
    "interval-filter:end": "End",
    "interval-filter:accept": "Accept",
    "interval-filter:reset": "Reset"
  }
}
</i18n>
