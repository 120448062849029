<template>
  <violations-list />
</template>

<script>
import violationsList from '@/modules/violations/violations-list'

export default {
  components: { violationsList }
}
</script>
