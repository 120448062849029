<template>
  <img
    :src="url"
    alt="photo"
    class="checklist-album-list-item__img">
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    url: {
      type: String,
      required: true
    }
  }
}
</script>
