<template>
  <div class="checklist-table">
    <r-title
      v-if="data.title"
      color-type="subhead">
      {{ data.title }}
    </r-title>
    <div class="checklist-table__wrapper">
      <checklist-table-row
        v-for="item in data.list"
        :key="item.id"
        class="checklist-table__row"
        :item="item" />
    </div>
  </div>
</template>

<script>
import checklistTableRow from './checklist-table-row'

export default {
  components: {
    checklistTableRow
  },
  props: {
    data: {
      type: Object,
      required: true,
      default: () => null
    }
  }
}
</script>

<style lang="scss" scoped>
.checklist-table {
  width: 100%;
  display: grid;
  grid-gap: 0.5rem;

  &__wrapper {
    border-radius: var(--border_radius);
    border: 1px solid var(--field_border);
    display: grid;
    padding: 0.5rem 1rem;
  }

  &__row {
    display: grid;
    grid-gap: 1.5rem;
    grid-template-columns: 1fr 1fr;
    padding: 0.5rem 0;
    border-bottom: 1px solid var(--field_border);

    &:last-child {
      border-bottom: none;
    }
  }
}
</style>
