import along from '@turf/along'
import bearing from '@turf/bearing'
import destination from '@turf/destination'
import turfLength from '@turf/length'
import { lineString } from '@turf/helpers'
import {
  // CommonSelectors,
  // Constants,
  doubleClickZoom,
  moveFeatures
} from '@/libs/map-draw/lib-utils'
import {
  // clearAllHelpers,
  clearAllRouteGeometry,
  clearActiveStopPointsStyling,
  nearestPointOnLine,
  getFeaturesByLayerId
} from '@/libs/map-draw/helpers'

export const createODDSimpleSelectMode = (MapboxDraw, map) => {
  const SimpleSelect = {}
  const customState = {
    hoveredLinkId: ''
  }

  const { model } = map.$store.state
  const { ids } = model

  SimpleSelect.clickAnywhere = function (state) {
    // Clear the re-render selection
    const wasSelected = this.getSelectedIds()
    if (wasSelected.length) {
      this.clearSelectedFeatures()
      wasSelected.forEach(id => this.doRender(id))
    }
    doubleClickZoom.enable(this)
    this.stopExtendedInteractions(state)

    map.$store.commit('SET', ['cardId', null])

    clearAllRouteGeometry(this.map)
    // clearReverseRouteStyling(this.map)
    clearActiveStopPointsStyling(this.map, map.$store)
  }

  SimpleSelect.dragMove = function (state, e) {
    // Dragging when drag move is enabled
    state.dragMoving = true
    e.originalEvent.stopPropagation()

    let delta
    const nodesFeatures = getFeaturesByLayerId(this.map, e, ids.nodes, 50)
    const linksFeatures = getFeaturesByLayerId(this.map, e, ids.links, 100)

    const source = this.map.getSource('signs_editor')

    if (nodesFeatures.length) {
      const { geometry } = nodesFeatures[0]

      delta = {
        lng: geometry.coordinates[0] - state.dragMoveLocation.lng,
        lat: geometry.coordinates[1] - state.dragMoveLocation.lat
      }

      moveFeatures(this.getSelected(), delta)

      if (source) {
        source.setData({
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              geometry
            },
            {
              type: 'Feature',
              geometry: {
                type: 'LineString',
                coordinates: [geometry.coordinates, geometry.coordinates]
              }
            }
          ]
        })
      }
    } else if (linksFeatures.length) {
      // get nearest link
      let length = Infinity
      let nearestLink = linksFeatures[0]

      for (let i = 0; i < linksFeatures.length; i++) {
        const link = linksFeatures[i]
        const nearest = nearestPointOnLine(
          [e.lngLat.lng, e.lngLat.lat],
          link.geometry.coordinates
        )
        const line = lineString([
          [e.lngLat.lng, e.lngLat.lat],
          nearest.geometry.coordinates
        ])
        const lineDistance = turfLength(line, { units: 'kilometers' })

        if (lineDistance < length) {
          length = lineDistance
          nearestLink = link
        }
      }

      const nearest = nearestPointOnLine(
        [e.lngLat.lng, e.lngLat.lat],
        nearestLink.geometry.coordinates
      )

      const { coordinates } = nearest.geometry

      if (map.$store.state.cardType === 'signs') {
        const { lng, lat } = e.lngLat
        const options = { units: 'kilometers' }
        const angle = bearing(nearest.geometry, {
          type: 'Point',
          coordinates: [lng, lat]
        })
        const destinationPoint = destination(nearest, 0.02, angle, options)
        const destinationLine = [
          nearest.geometry.coordinates,
          destinationPoint.geometry.coordinates
        ]
        const point = along(lineString(destinationLine), 0.02, options)

        delta = {
          lng: point.geometry.coordinates[0] - state.dragMoveLocation.lng,
          lat: point.geometry.coordinates[1] - state.dragMoveLocation.lat
        }

        const connectionCoords = [
          nearest.geometry.coordinates,
          point.geometry.coordinates
        ]

        const [feature] = this.getSelected()
        feature.setProperty('projection', nearest.geometry)

        moveFeatures(this.getSelected(), delta)

        if (source) {
          source.setData({
            type: 'FeatureCollection',
            features: [
              {
                type: 'Feature',
                geometry: nearest.geometry
              },
              {
                type: 'Feature',
                geometry: {
                  type: 'LineString',
                  coordinates: connectionCoords
                }
              }
            ]
          })
        }
      } else {
        delta = {
          lng: coordinates[0] - state.dragMoveLocation.lng,
          lat: coordinates[1] - state.dragMoveLocation.lat
        }
        moveFeatures(this.getSelected(), delta)
      }
    }

    const newFeature = this.getSelected()
    const newCoords = newFeature[0].coordinates

    customState.previousCoords = newCoords
    state.dragMoveLocation = {
      lng: newCoords[0],
      lat: newCoords[1]
    }
  }

  return { ...MapboxDraw.modes.simple_select, ...SimpleSelect }
}
