import turfLength from '@turf/length'
import { lineString } from '@turf/helpers'
import { Constants } from '@/libs/map-draw/lib-utils'
import {
  getFeaturesByLayerId,
  nearestPointOnLine,
  isPointInLinkArea
} from '@/libs/map-draw/helpers'

export const createODDDrawPointsMode = (MapboxDraw, map) => {
  const DrawPoints = {}
  const { model, books } = map.$store.state
  const { ids } = model
  const { eventClasses } = books

  DrawPoints.onTap = DrawPoints.onClick = function (state, e) {
    const { lng, lat } = e.lngLat
    const nodesFeatures = getFeaturesByLayerId(this.map, e, ids.nodes, 50)
    const linksFeatures = getFeaturesByLayerId(this.map, e, ids.links, 100)

    if (nodesFeatures.length) {
      let length = Infinity
      let feature = nodesFeatures[0]

      for (let i = 0; i < nodesFeatures.length; i++) {
        const node = nodesFeatures[i]
        const line = lineString([[lng, lat], node.geometry.coordinates])
        const lineDistance = turfLength(line, { units: 'kilometers' })

        if (lineDistance < length) {
          length = lineDistance
          feature = nodesFeatures[i]
        }
      }

      const { coordinates } = feature.geometry

      // set properties
      state.point.updateCoordinate('', coordinates[0], coordinates[1])
      state.point.setProperty('node_id', feature.properties.id)
      state.point.setProperty('name', 'Новое перекрытие')
      state.point.setProperty('event_class_id', eventClasses[0].id)
      state.point.setProperty('start_time', null)
      state.point.setProperty('end_time', null)

      //  removeStopPointsHelpers(this.map)
      this.map.fire(Constants.events.CREATE, {
        features: [state.point.toGeoJSON()]
      })

      // change mode
      this.changeMode(Constants.modes.SIMPLE_SELECT, {
        featureIds: [state.point.id]
      })

      map.$store.commit('SET', ['editorState.mode', 'select'])
    } else if (linksFeatures.length) {
      const link = linksFeatures.find(l =>
        isPointInLinkArea([lng, lat], l.geometry.coordinates, 100)
      )

      if (link) {
        const nearest = nearestPointOnLine(
          [lng, lat],
          link.geometry.coordinates
        )
        const { id } = link.properties

        // set properties
        state.point.updateCoordinate(
          '',
          nearest.geometry.coordinates[0],
          nearest.geometry.coordinates[1]
        )
        state.point.setProperty('link_id', id)
        state.point.setProperty('name', 'Новое перекрытие')
        state.point.setProperty('event_class_id', eventClasses[0].id)
        state.point.setProperty('start_time', null)
        state.point.setProperty('end_time', null)

        this.map.fire(Constants.events.CREATE, {
          features: [state.point.toGeoJSON()]
        })

        // change mode
        this.changeMode(Constants.modes.SIMPLE_SELECT, {
          featureIds: [state.point.id]
        })

        map.$store.commit('SET', ['editorState.mode', 'select'])
      }
    }
  }

  return { ...MapboxDraw.modes.draw_point, ...DrawPoints }
}
