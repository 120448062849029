export const mapColors = {
  default: '#2e93fc',
  white: '#fff',
  baseColor: '#3A8FD2',
  routeColor: '#d63838',
  activeColor: '#55b77e',
  reverseColor: '#99ddb5',
  ptRouteColor: '#FF8A48',
  ptEditRouteColor: '#ff5949'
}
