export const getChecklistRequestConfig = id => {
  return {
    where: [{ field: 'id', op: '=', value: id }],
    include: {
      application: {
        include: {
          contractor_repr: {},
          applications_employees: {
            include: {
              employee: {}
            }
          },
          vehicle: {
            only: [
              'driver_full_name',
              'vrc_sn',
              'vrc_full_name',
              'passport',
              'reg_number',
              'vin',
              'year',
              'mileage'
            ],
            include: {
              vehicle_type: { only: ['name'] },
              model: { only: ['name'] },
              contractor: { only: ['name'] }
            }
          }
        }
      },
      geozone: { only: ['name', 'geom'] },
      checklist_inspectors: {
        only: ['inspector_id'],
        include: { inspector: {} }
      }
    }
  }
}

export const tooltips = [
  {
    id: '1',
    tooltip: [
      'Порошковые огнетушители, установленные на транспортном средстве вне кабины или салона и подвергающиеся воздействию неблагоприятных климатических и физических факторов, перезаряжается не реже одного раза в 12 месяцев.',
      'Остальные огнетушители - не реже одного раза в 24 месяца.'
    ],
    label:
      'Автобус оснащён минимум двумя огнетушителями: один расположен в кабине, другой в салоне'
  },
  {
    id: '2',
    tooltip: [
      'Порошковые огнетушители, установленные на транспортном средстве вне кабины или салона и подвергающиеся воздействию неблагоприятных климатических и физических факторов, перезаряжается не реже одного раза в 12 месяцев.',
      'Остальные огнетушители - не реже одного раза в 24 месяца.'
    ],
    label:
      'Огнетушители расположены  в легкодоступном месте и защищены от воздействия осадков, солнечных лучей и грязи'
  },
  {
    id: '3',
    tooltip: [
      'Порошковые огнетушители, установленные на транспортном средстве вне кабины или салона и подвергающиеся воздействию неблагоприятных климатических и физических факторов, перезаряжается не реже одного раза в 12 месяцев.',
      'Остальные огнетушители - не реже одного раза в 24 месяца.'
    ],
    label: 'Срок перезарядки порошкового огнетушителя'
  },
  {
    id: '4',
    tooltip: [
      'Искрогаситель исправен (отсутствуют вмятины, царапины и дефекты антикоррозионного покрытия. Корпус искрогасителя, разъемные и неразъемные соединения герметичны)'
    ],
    label: 'Искрогаситель исправен'
  }
]
