import {
  getDatasourcesByDatatype, getModelChildrenIds,
  sortDataByField
} from '@/utils'

export class ModelController {
  constructor (parent) {
    this.parent = parent
    this.$store = parent.$store
    this.mapgl = parent.mapgl
  }

  async loadModel () {
    this.$store.commit('LOG', 'start model loading')
    try {
      const where = [{ field: 'datatype', op: '=', value: 'model' }]
      const models = await getDatasourcesByDatatype(this.parent, where)
      const model = models.find(m => !!m.is_main_model) || models[0]
      const { id, children, name } = model

      const ids = getModelChildrenIds(children)

      // set signs manually
      ids.signs = 'e010924a-220b-4ecf-b423-9e756ff1d18e'
      ids.sign_icons = '3c11eb14-f35f-4bc7-b03f-997aaa477959'

      this.$store.commit('SET_MODEL', { id, ids, name, children })
      this.$store.commit('SET', ['modelLoaded', true])
      this.$store.commit('LOG', `model id: ${id}`)
      this.$store.commit('LOG', `model name: ${name}`)
      this.$store.commit('LOG', `model ids: ${JSON.stringify(ids)}`)

      await this.loadBooks(ids)
      await this.loadLists()
    } catch (error) {
      console.log(error)
      this.$store.commit('LOG', `model loading error ${error}`)
    } finally {
      this.$store.commit('LOG', 'loadModel() ends')
    }
  }

  async loadBooks (ids) {
    try {
      this.$store.commit('LOG', 'loadBooks() started')
      const { sign_icons, event_classes } = ids
      const signIconsUrl = `objectInfo/${sign_icons}`
      const eventClassesUrl = `objectInfo/${event_classes}`

      const signIcons = await this.$store.dispatch('GET_REQUEST', {
        url: signIconsUrl
      })
      const eventClasses = await this.$store.dispatch('GET_REQUEST', {
        url: eventClassesUrl
      })

      this.$store.commit('SET_BOOK', {
        type: 'signIcons',
        book: sortDataByField(Object.values(signIcons.data), 'name', 'reverse')
      })
      this.$store.commit('LOG', `signIcons.data: ${JSON.stringify(signIcons.data)}`)
      this.$store.commit('SET_BOOK', {
        type: 'eventClasses',
        book: Object.values(eventClasses.data)
      })
    } catch (e) {
      // throw new Error(e)
      this.$store.commit('LOG', `loadBooks() error ${e}`)
    } finally {
      this.$store.commit('LOG', 'loadBooks() done')
    }
  }

  getEventsFilter () {
    const config = {}
    const { category, interval } = this.parent.eventsFilter
    const { eventClasses } = this.$store.state.books

    if (interval?.active) {
      const { from, to } = interval.prop.interval
      const validFrom = this.parent.$rDate.format(from, 'iso')
      const validTo = this.parent.$rDate.format(to, 'iso')
      const op =
        from && to ? 'between' : from && !to ? '>' : to & !from ? '<' : null
      const value =
        from && to
          ? validFrom + '/' + validTo
          : from && !to
            ? validFrom
            : to & !from
              ? validTo
              : null

      if (op && value) {
        if (!config.where) {
          config.where = []
        }
        config.where.push({ field: 'start_time', value, op })
      }
    }

    if (category && category !== 'Все') {
      const eventId = eventClasses.find(ev => ev.name === category)?.id
      if (eventId) {
        if (!config.where) {
          config.where = []
        }
        config.where.push({ field: 'event_class_id', value: eventId, op: '=' })
      }
    }

    return config
  }

  async loadLists () {
    try {
      this.$store.commit('LOG', 'loadLists() started')
      const { ids } = this.$store.state.model

      const signsConfig = {
        include: {
          sign_icon: {
            only: ['id', 'resource_id']
          }
        }
      }

      const eventsConfig = {
        include: { event_class: { only: ['name'] } }
      }

      const { where } = this.getEventsFilter()
      if (where) {
        eventsConfig.where = where
      }

      const signsUrl = `objectInfo/${ids.signs}?config=${JSON.stringify(
      signsConfig
    )}`
      const eventsUrl = `objectInfo/${ids.events}?config=${JSON.stringify(
      eventsConfig
    )}`

      const signsData = await this.$store.dispatch('GET_REQUEST', {
        url: signsUrl
      })
      const eventsData = await this.$store.dispatch('GET_REQUEST', {
        url: eventsUrl
      })

      const signs = Object.values(signsData.data)
      const events = Object.values(eventsData.data)
      // set lists
      this.$store.commit('SET_LIST', {
        type: 'signs',
        list: signs
      })

      this.$store.commit('SET_LIST', {
        type: 'events',
        list: events
      })
    } catch (e) {
      console.log(e)
      this.$store.commit('LOG', `loadLists() error ${e}`)
    } finally {
      this.$store.commit('LOG', 'loadLists() done')
    }
  }
}
