<template>
  <div class="bdd-checklists">
    <component :is="componentName" />
  </div>
</template>

<script>
import checklistList from './components/checklist-list/checklist-list'
import checklist from './components/checklist/checklist'

export default {
  components: {
    checklistList,
    checklist
  },
  computed: {
    componentName () {
      const { id } = this.$route.query

      return id ? 'checklist' : 'checklist-list'
    }
  }
}
</script>

<style lang="scss">
.bdd-checklists {
  height: calc(100% - 4rem);
  width: 100%;
  display: flex;
  justify-content: center;
  overflow-y: auto;
}
</style>
