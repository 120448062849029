<template>
  <ul class="bdd-nav__container">
    <li
      v-for="navItem in navConfig"
      :key="navItem.path"
      class="bdd-nav__container--item"
      @click="clickHandler(navItem.path)">
      <r-icon-button
        :caption="navItem.name"
        :icon="navItem.icon"
        :active="navItem.active" />
    </li>
  </ul>
</template>

<script>
export default {
  data () {
    return {
      navConfig: [
        {
          path: '/bdd-checklists/',
          active: true,
          name: 'Список',
          icon: 'list'
        },
        {
          path: '/',
          active: false,
          name: 'Модули',
          icon: 'home'
        }
      ]
    }
  },
  watch: {
    $route (to, _) {
      this.activeToggler(to.path)
    }
  },
  methods: {
    clickHandler (route) {
      if (this.$route.path !== route) this.$router.push(`${route}`)
    },
    activeToggler (path) {
      this.navConfig.forEach(i => {
        i.active = false
      })
      const currentRoute = this.navConfig.find(i => i.path === path)
      if (currentRoute) currentRoute.active = true
    }
  }
}
</script>

<style lang="scss">
.bdd-nav__container {
  position: fixed;
  bottom: 0.5rem;
  right: 0.5rem;
  left: 0.5rem;
  display: grid;
  z-index: 500;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  justify-content: space-between;
  justify-items: stretch;
  align-items: center;
  background-color: var(--bg_surface);
  overflow: hidden;
  border-radius: 0.25rem;
  box-shadow: var(--mid_shadow);

  &--item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .r-icon-button {
    justify-content: center;
    width: 100% !important;
    border-radius: 0;
  }
}
</style>
