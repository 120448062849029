<template>
  <div id="app-main">
    <router-view />
  </div>
</template>

<script>
import { toggleBodyTheme } from '@/utils'

export default {
  async created () {
    this.syncStorageData()

    this.disableScale()

    const vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)

    this.initResizeEffect()
  },
  methods: {
    initResizeEffect () {
      window.addEventListener('resize', () => {
        const vh = window.innerHeight * 0.01
        document.documentElement.style.setProperty('--vh', `${vh}px`)
      })
    },
    async syncStorageData () {
      if (localStorage.initialState) {
        const initialState = JSON.parse(localStorage.initialState)
        const keys = Object.keys(initialState)
        keys.forEach(key => {
          if (initialState[key]) {
            this.$store.commit('SET', [
              `initialState.${key}`,
              initialState[key]
            ])
          }
        })
      }
      const darkTheme = JSON.parse(localStorage.getItem('darkTheme'))
      this.$store.commit('SET', ['darkTheme', darkTheme ?? true])

      toggleBodyTheme()
    },
    disableScale () {
      let lastTouchEnd = 0
      document.addEventListener(
        'touchend',
        e => {
          const now = new Date().getTime()
          if (now - lastTouchEnd <= 300) {
            e.preventDefault()
          }
          lastTouchEnd = now
        },
        false
      )
    }
  }
}
</script>
