<template>
  <div class="files-comments__comments">
    <send-comment
      :id="id"
      :source_id="source_id"
      @load="loadComments" />
    <r-text
      v-if="!comments.length"
      type="caption">
      Комментарии отсутствуют
    </r-text>
    <comments-list
      v-else
      :id="id"
      :source_id="source_id"
      :comments="comments"
      @load="loadComments" />
  </div>
</template>

<script>
import sendComment from './send-comment'
import commentsList from './comments-list'

export default {
  components: { sendComment, commentsList },
  props: {
    source_id: {
      type: String,
      default: null
    },
    id: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      comments: []
    }
  },
  watch: {
    id () {
      this.loadComments()
    }
  },
  mounted () {
    this.loadComments()
  },
  methods: {
    async loadComments () {
      try {
        this.$emit('loading', ['comments', true])
        const url = `objectComments/${this.source_id}/${this.id}`
        const { data } = await this.$store.dispatch('GET_REQUEST', { url })
        this.comments = data.reverse()
      } catch (e) {
        console.log(e)
      } finally {
        this.$emit('loading', ['comments', false])
      }
    }
  }
}
</script>
