var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"bottomSheet",class:[
    'r-bottom-sheet',
    {
      opened: _vm.opened,
      closed: _vm.opened === false,
      moving: _vm.moving
    }
  ],on:{"mousedown":function($event){$event.stopPropagation();return _vm.clickOnBottomSheet.apply(null, arguments)},"touchstart":function($event){$event.stopPropagation();return _vm.clickOnBottomSheet.apply(null, arguments)}}},[(_vm.overlay)?_c('div',{staticClass:"r-bottom-sheet__backdrop"}):_vm._e(),_c('div',{ref:"bottomSheetCard",class:[
      'r-bottom-sheet__card',
      { stripe: _vm.stripe, square: !_vm.rounded || _vm.fullRounded },
      _vm.effect
    ],style:({
      bottom: _vm.cardP + 'px',
      maxWidth: _vm.maxWidth,
      maxHeight: _vm.maxHeight,
      height: _vm.initialHeight
    })},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hideBar),expression:"!hideBar"}],ref:"pan",staticClass:"r-bottom-sheet__pan"},[_c('div',{staticClass:"r-bottom-sheet__bar"})]),(_vm.headerVisible)?_c('div',{staticClass:"r-bottom-sheet__header"},[(_vm.backButton)?_c('r-back-button',{staticClass:"r-bottom-sheet__control-button back",nativeOn:{"click":function($event){return _vm.$emit('back')}}}):_vm._e(),(_vm.title)?_c('r-title',{staticClass:"r-bottom-sheet__title"},[_vm._v(" "+_vm._s(_vm.title)+" ")]):(_vm.name || _vm.desc)?_c('div',{staticClass:"r-bottom-sheet__title"},[_c('r-title',{staticClass:"r-bottom-sheet__name",attrs:{"type":"title-2"}},[_vm._v(" "+_vm._s(_vm.name)+" ")]),_c('r-text',{staticClass:"r-bottom-sheet__desc",attrs:{"type":"caption"}},[_vm._v(" "+_vm._s(_vm.desc)+" ")])],1):_vm._e(),(_vm.closeButton)?_c('r-close-button',{staticClass:"r-bottom-sheet__control-button close",nativeOn:{"click":function($event){return _vm.close.apply(null, arguments)}}}):_vm._e()],1):_vm._e(),_c('div',{ref:"bottomSheetCardContent",staticClass:"r-bottom-sheet__content"},[_vm._t("default")],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }