<template>
  <div
    :class="['r-modal-card__block', main ? 'r-modal-card__block--main' : '']"
    :style="getStyle()">
    <div
      v-if="title || icon || action"
      class="r-modal-card__block-header"
      :style="toggler ? 'cursor:pointer' : ''"
      @click.self="toggle">
      <div class="r-modal-card__block-title">
        <r-title
          v-if="title"
          :type="`subtitle-${main ? 1 : 2}`">
          <r-icon
            v-if="icon"
            :size="24"
            :name="icon" />
          {{ title }}
        </r-title>
        <slot name="title-button" />
      </div>
      <r-button
        v-if="actionName || action"
        mini
        :type="actionType"
        :icon="actionIcon || null"
        @click="$emit('action')">
        {{ actionName }}
      </r-button>
      <!-- Toggler -->
      <r-button
        v-if="toggler"
        simple
        bold
        :icon="isOpen ? 'chevron-up' : 'chevron-down'"
        @click.native="toggle">
        {{ isOpen ? 'Свернуть' : 'Развернуть' }}
      </r-button>
      <!-- Toggler -->
      <slot name="button" />
    </div>

    <slot v-if="isOpen" />
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null
    },
    icon: {
      type: String,
      default: null
    },
    main: {
      type: Boolean,
      default: false
    },
    transparent: {
      type: Boolean,
      default: false
    },
    action: {
      type: Boolean,
      default: false
    },
    toggler: {
      type: Boolean,
      default: false
    },
    open: {
      type: Boolean,
      default: true
    },
    actionName: {
      type: String,
      default: null
    },
    actionIcon: {
      type: String,
      default: null
    },
    actionType: {
      type: String,
      default: 'default'
    },
    noBorder: {
      type: Boolean,
      default: false
    },
    noPadding: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isOpen: this.open
    }
  },
  methods: {
    getStyle () {
      const styleHash = [
        {
          key: 'transparent',
          value: 'background-color: transparent;'
        },
        {
          key: 'noBorder',
          value: 'border: none;'
        },
        {
          key: 'noPadding',
          value: 'padding: 0;'
        }
      ]

      return styleHash
        .reduce((a, c) => {
          if (this[c.key]) a.push(c.value)
          return a
        }, [])
        .join()
    },
    toggle (v) {
      if (!this.toggler) return
      this.isOpen = typeof v === 'boolean' ? v : !this.isOpen
    }
  }
}
</script>
