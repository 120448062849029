<template>
  <div
    class="r-block"
    :class="[
      {
        row,
        col,
        rtl,
        ovh,
        start,
        center,
        bottom,
        top,
        scroll,
        radius,
        stretch,
        disabled,
        noPadding,
        bottomSpace,
        spaceBetween
      },
      borderClass,
      bgClass
    ]"
    :style="style">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    border: {
      type: [Boolean, String],
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    radius: {
      type: Boolean,
      default: false
    },
    row: {
      type: Boolean,
      default: false
    },
    rtl: {
      type: Boolean,
      default: false
    },
    col: {
      type: Boolean,
      default: false
    },
    gap: {
      type: Number,
      default: 0.5
    },
    start: {
      type: Boolean,
      default: false
    },
    center: {
      type: Boolean,
      default: false
    },
    bottom: {
      type: Boolean,
      default: false
    },
    top: {
      type: Boolean,
      default: false
    },
    noPadding: {
      type: Boolean,
      default: false
    },
    spaceBetween: {
      type: Boolean,
      default: false
    },
    stretch: {
      type: Boolean,
      default: false
    },
    scroll: {
      type: Boolean,
      default: false
    },
    ovh: {
      type: Boolean,
      default: false
    },
    padding: {
      type: Number,
      default: 0.5
    },
    width: {
      type: String,
      default: null
    },
    background: {
      type: String,
      default: null
    },
    bottomSpace: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    style() {
      return {
        gridGap: `${this.gap}rem`,
        padding: `${this.padding}rem`,
        width: this.width
      }
    },
    borderClass() {
      switch (typeof this.border) {
        case 'boolean':
          return this.border === true ? 'border' : ''
        case 'string':
          return `border-${this.border}`
        default:
          return ''
      }
    },
    bgClass() {
      if (!this.background) return null
      return `bg-${this.background}`
    }
  }
}
</script>

<style lang="scss" scoped>
.r-block {
  display: grid;
  padding: 0.5rem;
  align-items: center;
  align-content: start;

  &.row {
    grid-auto-flow: row;
  }

  &.scroll {
    height: 100%;
    overflow: auto;
  }

  &.ovh {
    overflow: hidden;
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.4;
  }

  &.start {
    justify-content: start;
  }

  &.center {
    justify-content: center;
  }

  &.bottom {
    align-items: end !important;
  }

  &.top {
    align-items: start !important;
  }

  &.spaceBetween {
    justify-content: space-between;
  }

  &.col {
    grid-auto-flow: column;
  }

  &.radius {
    border-radius: var(--border-radius);
  }

  &.spaceBetween {
    justify-content: space-between;
  }

  &.stretch {
    // justify-content: stretch;
    grid-template-columns: repeat(auto-fit, minmax(50%, 100%));
  }

  &.noPadding {
    padding: 0 !important;
  }

  &.rtl {
    direction: rtl;
  }

  &.bg {
    &-panel-primary {
      background-color: var(--bg_panel_primary);
    }
    &-containers {
      background-color: var(--bg_containers);
    }
    &-containers-white {
      background-color: var(--bg_containers_white);
    }
  }

  &.bottomSpace {
    padding-bottom: 1rem !important;
  }

  &.border {
    border: 1px solid var(--dividers_low_contrast);

    &-top {
      border-top: 1px solid var(--dividers_low_contrast);
    }
    &-bottom {
      border-bottom: 1px solid var(--dividers_low_contrast);
    }
    &-right {
      border-right: 1px solid var(--dividers_low_contrast);
    }
    &-left {
      border-left: 1px solid var(--dividers_low_contrast);
    }
  }

  &.bg {
    &-containers {
      background-color: var(--bg_containers);
    }
    &-containers-white {
      background-color: var(--bg_containers_white);
    }
  }
}
</style>
