// Main
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vSelect from 'vue-select'

// Drag, swipe & touch
import VueRecognizer from 'vue-recognizer'

// Element
import {
  Button,
  Select,
  Option,
  DatePicker,
  TimePicker,
  Input,
  Checkbox,
  Radio,
  RadioGroup,
  RadioButton,
  Tabs,
  TabPane,
  Tooltip,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Notification,
  Message,
  MessageBox,
  Loading,
  Switch,
  Upload,
  Dialog,
  Popover,
  InputNumber,
  Icon
} from 'element-ui'
import locale from 'element-ui/lib/locale'
import lang from './local/element_local'
import 'element-ui/lib/theme-chalk/index.css'

// Globals
import '@/components/globals'

// Styles
import '@/styles/main.scss'
import '@/styles/ritm-variables.scss'
import loader from './loader'

// Utils
import { rDate } from '@/utils'

Vue.prototype.$rDate = rDate

Vue.config.productionTip = false
locale.use(lang)

vSelect.props.components.default = () => ({
  OpenIndicator: {
    render: createElement => createElement('span', ' ')
  }
})
// Element components
Vue.use(Button)
Vue.use(Select)
Vue.use(Option)
Vue.use(Input)
Vue.use(TimePicker)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(DatePicker)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(Checkbox)
Vue.use(RadioButton)
Vue.use(InputNumber)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Dropdown)
Vue.use(Switch)
Vue.use(Upload)
Vue.use(Tooltip)
Vue.use(Dialog)
Vue.use(Popover)
Vue.use(Icon)

Vue.use(Loading.directive)

Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$notify = Notification
Vue.prototype.$message = Message

Vue.component('v-select', vSelect)

Vue.use(VueRecognizer)

Vue.directive('loading', loader)

export default new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
