<template>
  <violations-create />
</template>

<script>
export default {
  components: {
    violationsCreate: () => import('@/modules/violations/violations-create.vue')
  }
}
</script>
