<template>
  <div class="checklist-album-upload">
    <attachments
      v-loading="loading"
      label="Загрузить фотографии"
      square
      hide-list
      :source="source"
      :accepted-types="['.jpg', '.jpeg', '.png']" />
  </div>
</template>

<script>
import attachments from '@/components/globals/attachments/attachments'
import { parseImage } from '@/utils'

export default {
  components: {
    attachments
  },
  props: {
    selectMode: {
      type: Boolean,
      default: false
    },
    attrId: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      loading: false,
      source: {
        file: []
      },
      checklistsUrl: this.$store.state.bdd.checklistsUrl,
      id: this.$route.query.id
    }
  },
  watch: {
    source: {
      handler: function (val) {
        if (!val?.file?.length) return
        this.uploadFiles(val.file)
      },
      deep: true
    }
  },
  methods: {
    async uploadFiles (filesList) {
      this.loading = true

      try {
        await Promise.all(
          filesList.map(async e => {
            const parsedImage = await parseImage(e)

            await this.uploadFile(parsedImage)
          })
        )
        this.$notify({
          message: 'Загрузка прошла успешно',
          duration: 1500,
          type: 'success',
          title: 'Загрузка'
        })
        this.source = { file: [] }
        this.$emit('load')
      } catch (e) {
        this.$notify({
          message: `Не удалось загрузить фото, ошибка: ${e}`,
          duration: 5000,
          type: 'error',
          title: 'Ошибка'
        })
        throw new Error(e)
      } finally {
        this.loading = false
      }
    },
    async uploadFile (image) {
      try {
        const formData = new FormData()

        formData.append('source_id', this.checklistsUrl)
        formData.append('obj_id', this.id)
        formData.append('file', image)

        if (this.selectMode && this.attrId) {
          formData.append('attr_id', this.attrId)
        }

        await this.$store.dispatch('POST_REQUEST', {
          url: 'upload_resource',
          data: formData
        })
      } catch (e) {
        throw new Error(e)
      }
    }
  }
}
</script>

<style lang="scss">
.checklist-album-upload {
  &__inner {
    display: grid;
    height: 100%;
    place-content: center;
  }

  .el-upload {
    &.el-upload--picture-card {
      background-color: var(--bg_containers);
      text-align: center;
      border: none;
      width: 7.5rem;
      height: 7.5rem;
    }
  }
}
</style>
