<template>
  <div class="radio-filter">
    <div class="popper-filter__content">
      <r-radio-group
        :button-list="filterValue"
        :active="defaultValue"
        @change="toggleValue" />
    </div>
    <r-button
      type="primary"
      @click="prepareData">
      Применить
    </r-button>
  </div>
</template>

<script>
export default {
  props: {
    filterValue: {
      type: Array,
      required: true
    },
    id: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      defaultValue: 'all'
    }
  },
  methods: {
    toggleValue (v) {
      this.defaultValue = v
      this.prepareData()
    },
    resetFilter () {
      this.toggleValue('all')
      this.prepareData()
    },
    prepareData () {
      const { id, defaultValue, filterValue } = this

      const data = {
        prop: filterValue.map(f => {
          return {
            ...f,
            value: f.id === defaultValue
          }
        }),
        id: id,
        active: defaultValue !== 'all'
      }

      this.$emit('load', data)
    }
  }
}
</script>

<style lang="scss">
.radio-filter {
  display: grid;
  grid-auto-flow: row;
  grid-gap: 1rem;
  padding: 0.5rem;
  align-items: center;
  justify-content: stretch;

  .r-radio-group .el-radio-button {
    &__inner .r-text {
      font-size: 14px !important;
      color: var(--text_primary) !important;
    }
  }

  .r-button {
    width: 100%;
  }
}
</style>
