<template>
  <div
    ref="bottomSheet"
    :class="[
      'r-bottom-sheet',
      {
        opened: opened,
        closed: opened === false,
        moving: moving
      }
    ]"
    @mousedown.stop="clickOnBottomSheet"
    @touchstart.stop="clickOnBottomSheet">
    <div
      v-if="overlay"
      class="r-bottom-sheet__backdrop" />
    <div
      ref="bottomSheetCard"
      :style="{
        bottom: cardP + 'px',
        maxWidth: maxWidth,
        maxHeight: maxHeight,
        height: initialHeight
      }"
      :class="[
        'r-bottom-sheet__card',
        { stripe: stripe, square: !rounded || fullRounded },
        effect
      ]">
      <div
        v-show="!hideBar"
        ref="pan"
        class="r-bottom-sheet__pan">
        <div class="r-bottom-sheet__bar" />
      </div>
      <div
        v-if="headerVisible"
        class="r-bottom-sheet__header">
        <r-back-button
          v-if="backButton"
          class="r-bottom-sheet__control-button back"
          @click.native="$emit('back')" />
        <r-title
          v-if="title"
          class="r-bottom-sheet__title">
          {{ title }}
        </r-title>
        <div
          v-else-if="name || desc"
          class="r-bottom-sheet__title">
          <r-title
            type="title-2"
            class="r-bottom-sheet__name">
            {{ name }}
          </r-title>
          <r-text
            type="caption"
            class="r-bottom-sheet__desc">
            {{ desc }}
          </r-text>
        </div>
        <r-close-button
          v-if="closeButton"
          class="r-bottom-sheet__control-button close"
          @click.native="close" />
      </div>
      <div
        ref="bottomSheetCardContent"
        class="r-bottom-sheet__content">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import Hammer from 'hammerjs'

export default {
  name: 'VueBottomSheet',
  props: {
    overlay: {
      type: Boolean,
      default: true
    },
    maxWidth: {
      type: String,
      default: '940px'
    },
    maxHeight: {
      type: String,
      default: '95%'
    },
    initialHeight: {
      type: String,
      default: '50%'
    },
    hideBar: {
      type: Boolean,
      default: false
    },
    clickToClose: {
      type: Boolean,
      default: false
    },
    swipeToClose: {
      type: Boolean,
      default: true
    },
    effect: {
      type: String,
      default: 'fx-default'
    },
    rounded: {
      type: Boolean,
      default: true
    },
    backButton: {
      type: Boolean,
      default: false
    },
    closeButton: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: () => {}
    },
    name: {
      type: String,
      default: () => {}
    },
    desc: {
      type: String,
      default: () => {}
    }
  },
  data () {
    // const vm = this
    return {
      inited: false,
      opened: false,
      hammer: {
        pan: null,
        content: null
      },
      contentScroll: 0,
      cardP: null,
      cardH: null,
      moving: false,
      fullRounded: false,
      full: false,
      stripe: 0
    }
  },
  computed: {
    headerVisible () {
      return (
        this.backButton ||
        this.closeButton ||
        this.title ||
        this.name ||
        this.desc
      )
    }
  },
  watch: {
    full (f) {
      this.fullRounded = f
    }
  },
  beforeDestroy () {
    if (this.hammer.pan) this.hammer.pan.destroy()
  },
  methods: {
    isIphone () {
      const iPhone = /iPhone/.test(navigator.userAgent) && !window.MSStream
      const aspect = window.screen.width / window.screen.height
      return iPhone && aspect.toFixed(3) === '0.462'
    },
    getInfo (delta, event) {
      // fn for debugging
      console.log({
        cardH: this.cardH,
        cardP: this.cardP,
        clientHeight: this.$refs.bottomSheetCard.clientHeight,
        delta,
        isFull: this.full,
        event
      })
    },
    move (event) {
      const delta = -event.deltaY

      // this.getInfo(delta, event)
      if (event.type === 'panup' || event.type === 'pandown') {
        this.moving = true
        if (-delta > 0) {
          this.cardP = delta
        } else {
          const card = this.$refs.bottomSheetCard
          card.style.height = `${card.clientHeight + delta}px`
          this.cardP = 0

          if (delta > 200) {
            card.height = this.maxHeight
          }
        }
      }

      if (event.isFinal) {
        this.moving = false
        if (delta < -200 && event.type === 'panend') {
          if (!this.full && this.swipeToClose) {
            this.opened = false
            this.cardP = -this.cardH - this.stripe
            document.body.style.overflow = ''
            this.$emit('closed')
          } else {
            this.$refs.bottomSheetCard.style.height = this.initialHeight
            this.cardH = this.initialHeight
            this.cardP = 0
            this.full = false
          }
        } else if (delta > 100) {
          this.full = true
          const { style, clientHeight } = this.$refs.bottomSheetCard
          style.height = this.maxHeight
          this.cardH = clientHeight
        } else {
          this.cardH = this.$refs.bottomSheetCard.clientHeight
          this.cardP = 0
        }
      }
    },
    init () {
      return new Promise(resolve => {
        this.stripe = this.isIphone() ? 20 : 0
        this.cardH = this.$refs.bottomSheetCard.clientHeight
        this.cardP =
          this.effect === 'fx-slide-from-right' ||
          this.effect === 'fx-slide-from-left'
            ? 0
            : `-${this.cardH + this.stripe}px`
        if (!this.inited) {
          this.inited = true
          const options = {
            recognizers: [
              [Hammer.Pan, { direction: Hammer.DIRECTION_VERTICAL }]
            ]
          }
          this.hammer.pan = new Hammer(this.$refs.pan, options)
          this.hammer.pan.on('panstart panup pandown panend', e => {
            this.move(e, 'pan')
          })
        }
        setTimeout(() => {
          resolve()
        }, 10)
      })
    },
    open () {
      this.init().then(() => {
        this.opened = true
        this.cardP = 0
        document.body.style.overflow = 'hidden'
        this.$emit('opened')
      })
    },
    close () {
      this.opened = false
      this.cardP =
        this.effect === 'fx-slide-from-right' ||
        this.effect === 'fx-slide-from-left'
          ? 0
          : -this.cardH - this.stripe
      document.body.style.overflow = ''
      this.$emit('closed')
    },
    clickOnBottomSheet (event) {
      if (this.clickToClose) {
        if (
          event.target.classList.contains('r-bottom-sheet__backdrop') ||
          event.target.classList.contains('r-bottom-sheet')
        ) {
          this.close()
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.r-bottom-sheet {
  z-index: 300;
  transition: all 0.4s ease;
  position: relative;

  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
    padding: 0 0.5rem 0.5rem 0.5rem;
  }

  &__title {
    width: 100%;
    &.r-title {
      text-align: center;
    }
  }

  &__desc {
    width: 100%;
    text-align: left;
  }

  &__name {
    width: 100%;
    text-align: left;
  }

  &__header {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 0.5rem;
    align-items: center;
    position: relative;
    width: 100%;
    padding: 0 4rem;
  }

  &__control-button {
    position: absolute;
    height: 56px;
    display: flex;
    align-items: center;
    padding-bottom: 1rem;
    &.close {
      right: 2rem;
    }
    &.back {
      left: 2rem;
    }
  }

  &__backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
    z-index: 300;
    opacity: 0;
    visibility: hidden;
  }

  &__card {
    display: grid;
    align-content: start;
    grid-gap: 0.5rem;
    width: 100%;
    position: fixed;
    background-color: var(--bg_panel_primary);
    box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.05),
      0px -4px 33px rgba(0, 0, 0, 0.05);
    border-radius: 8px 8px 0 0;
    left: 50%;
    z-index: 300;
    padding-bottom: 3rem !important;
    margin: 0 auto;

    &.square {
      border-radius: 0;
    }

    &.stripe {
      padding-bottom: 1rem;
    }

    &.fx-default {
      transform: translate(-50%, 0);
      transition: bottom 0.3s ease;
    }

    &.fx-fadein-scale {
      transform: translate(-50%, 0) scale(0.7);
      opacity: 0;
      transition: all 0.3s;
    }

    &.fx-slide-from-right {
      transform: translate(100%, 0);
      opacity: 0;
      transition: all 0.3s cubic-bezier(0.25, 0.5, 0.5, 0.9);
    }

    &.fx-slide-from-left {
      transform: translate(-100%, 0);
      opacity: 0;
      transition: all 0.3s cubic-bezier(0.25, 0.5, 0.5, 0.9);
    }
  }

  &__pan {
    padding: 0.75rem;
  }

  &__bar {
    display: block;
    width: 50px;
    height: 3px;
    border-radius: 14px;
    margin: 0 auto;
    cursor: pointer;
    background: rgba(0, 0, 0, 0.3);
  }

  &.closed {
    opacity: 0;
    visibility: hidden;
    .r-bottom-sheet__backdrop {
      animation: hide 0.3s ease;
    }
  }

  &.moving {
    .r-bottom-sheet__card {
      transition: none;
    }
  }

  &.opened {
    position: fixed;
    top: 0;
    left: 0;
    width: 0;
    height: 0;

    .r-bottom-sheet__backdrop {
      animation: show 0.3s ease;
      opacity: 1;
      visibility: visible;
    }

    .r-bottom-sheet__card {
      &.fx-fadein-scale {
        transform: translate(-50%, 0) scale(1);
        opacity: 1;
      }
      &.fx-slide-from-right {
        transform: translate(-50%, 0);
        opacity: 1;
      }
      &.fx-slide-from-left {
        transform: translate(-50%, 0);
        opacity: 1;
      }
    }
  }
}

@keyframes show {
  0% {
    opacity: 0;
    visibility: hidden;
  }

  100% {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes hide {
  0% {
    opacity: 1;
    visibility: visible;
  }

  100% {
    opacity: 0;
    visibility: hidden;
  }
}
</style>
