<template>
  <button
    :style="`width:${width}; ${
      revert ? 'justify-content: space-between; direction: rtl' : ''
    }; grid-gap: ${gap}`"
    :class="[`r-icon-button ${active ? 'active' : ''}`]">
    <r-icon
      :name="icon"
      :size="size"
      :color-type="active ? 'white' : ''" />
    <span
      v-if="caption"
      class="r-icon-button__caption">
      {{ caption }}
    </span>
    <div
      class="r-icon-button__name"
      :style="`color: ${color}`">
      <slot />
    </div>
  </button>
</template>

<script>
export default {
  props: {
    icon: {
      required: true,
      type: String
    },
    caption: {
      required: false,
      type: String,
      default: () => {}
    },
    size: {
      type: Number,
      default: 20
    },
    active: {
      type: Boolean,
      default: false
    },
    revert: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: '36px'
    },
    color: {
      type: String,
      default: 'var(--accent_primary)'
    },
    gap: {
      type: String,
      default: '0'
    }
  }
}
</script>

<style lang="scss">
.r-icon-button {
  display: grid;
  justify-content: center;
  align-items: center;
  grid-auto-flow: row;
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 0.25rem;
  border-radius: 0.25rem;
  height: 100%;
  transition: 0.15s;

  &:hover {
    background-color: var(--accent_hover);
  }
  &:focus {
    background-color: transparent;
  }

  &.active {
    background-color: var(--accent_active);
    .r-icon-button__caption {
      color: var(--text_primary);
    }
  }

  &__caption {
    font-size: 10px;
    color: var(--text_secondary);
  }

  &__name {
    font-size: 14px;
    font-weight: 600;
    color: var(--text_primary);
    grid-column: 2;
    grid-row: 1;
  }
}
</style>
