<template>
  <button
    :class="['r-delete-button', cross ? 'simple' : '', { mini, tiny }]"
    @click="beforeDelete">
    <r-icon
      :name="cross ? 'close-delete' : 'trash'"
      :size="tiny ? 18 : 22"
      :color-type="cross ? 'default' : 'error'" />
    <span
      v-if="!mini && !tiny"
      class="r-delete-button__text">Удалить</span>
    <div class="r-delete-button__text">
      <slot />
    </div>
  </button>
</template>

<script>
import { notifyFactory, errorParser } from '@/utils'

export default {
  props: {
    mini: {
      type: Boolean,
      default: () => false
    },
    tiny: {
      type: Boolean,
      default: () => false
    },
    cross: {
      type: Boolean,
      default: () => false
    },
    simple: {
      type: Boolean,
      default: () => false
    },
    source_id: {
      type: String,
      default: () => null
    },
    id: {
      type: String,
      default: () => null
    }
  },
  methods: {
    beforeDelete () {
      if (this.simple) {
        this.$emit('delete')
        return
      }
      this.$confirm('Это действие невозможно отменить.', 'Удалить объект?', {
        customClass: 'r-message-box',
        type: 'warning',
        closeOnPressEscape: false,
        closeOnClickModal: false
      })
        .then(() => {
          this.$emit('beforeDelete')
        })
        .then(async () => {
          const { source_id, id } = this

          if (source_id && id) {
            await this.deleteRequest(source_id, id)

            const message = 'Удаление выполнено'
            this.$notify(notifyFactory('success', message))
            this.$emit('afterDelete')
          } else {
            this.$emit('delete')
          }
        })
        .catch(e => {
          if (e === 'cancel') return
          this.errorHandler(e)
        })
    },
    errorHandler (error) {
      const title = 'Ошибка!'
      const message = 'Удаление не выполнено, обратитесь к администратору'
      errorParser.call(this, error, message, title)
    },
    async deleteRequest (source_id, id) {
      await this.$store.dispatch('DELETE_REQUEST', {
        url: `objectInfo/${source_id}?id=${id}`
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.r-delete-button {
  display: grid;
  justify-content: center;
  grid-auto-flow: column;
  align-items: center;
  border-radius: var(--border_radius);
  background-color: transparent;
  border: none;
  height: 36px;
  grid-gap: 0.5rem;
  transition: background-color ease 0.15s;
  padding: 0 0.5rem;
  margin: 0;
  cursor: pointer;

  &__text {
    max-width: 100%;
    color: var(--accent_error);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    line-height: 1.25rem;
    font-weight: 600;

    &:empty {
      display: none;
    }
  }

  &.tiny {
    width: 28px;
    height: 28px;
    padding: 0;
  }

  &.simple {
    height: auto;

    .r-delete-button__text {
      color: var(--text_primary);
    }

    &:hover {
      .r-delete-button__text {
        color: var(--accent_error);
      }

      .r-icon {
        fill: var(--accent_error) !important;
      }
    }
  }

  &:hover {
    background-color: var(--accent_error_hover);
  }
}
</style>
