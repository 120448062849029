<template>
  <div class="app-select">
    <div
      v-if="$route.name === 'main'"
      class="app-select__content">
      <div class="app-select__header">
        <r-title> Выберите модуль </r-title>
      </div>
      <div class="app-select__list">
        <router-link
          v-for="route in routes"
          :key="route.id"
          class="app-select__link"
          :to="`/${route.path}`">
          <div class="app-select__icon">
            <r-icon
              :name="route.icon"
              :size="32" />
          </div>
          <div class="app-select__info">
            <r-text>
              {{ route.name }}
            </r-text>
            <r-text type="caption">
              {{ route.description }}
            </r-text>
          </div>
          <div class="app-select__arrow">
            <r-icon
              name="chevron-right"
              :size="24" />
          </div>
        </router-link>
      </div>
    </div>
    <router-view />
  </div>
</template>

<script>
const routes = [
  {
    id: 'odd',
    icon: 'line',
    path: 'odd',
    name: 'Редактор ОДД',
    description: 'Создание объектов и событий на дорогах'
  },
  {
    id: 'bdd',
    icon: 'bdd',
    path: 'bdd-checklists',
    name: 'Листы осмотра транспорта (БДД)',
    description: 'Заполнение листа осмотра транспорта'
  },
  {
    id: 'violations',
    icon: 'acts-document',
    path: 'violations/list',
    name: 'Реестр нарушений',
    description: 'Фиксация нарушений'
  }
]
export default {
  data () {
    return {
      refreshToken: null,
      ticker: 0,
      routes
    }
  },
  created() {
    this.loadToken()
    this.refreshToken = setInterval(this.loadToken, 300000)
  },
  methods: {
    async loadToken() {
      try {
        if (this.ticker > 3) {
          this.$router.push('/access-denied')
        }

        this.ticker += 1

        const { data, status } = await this.$store.dispatch('LOGIN', {
          url: 'login'
        })

        if (status === 411) {
          await this.loadToken()
        } else {
          localStorage.setItem('group_id', JSON.stringify(data.group_id))
        }
      } catch (e) {
        throw new Error(e)
      } finally {
        this.ticker = 0
      }
    }
  }
}
</script>

<style lang="scss">
.app-select {
  height: 100%;
  width: 100%;
  display: grid;
  align-items: center;
  background-color: var(--bg_panel_primary);
  justify-items: center;
  justify-content: stretch;

  &__header {
    text-align: center;
    padding: 0.5rem;
  }

  &__content {
    display: grid;
    grid-auto-flow: row;
    align-items: center;
    grid-gap: 0.5rem;
    background-color: var(--bg_panel_primary);
    border-radius: var(--border_radius);
    box-shadow: 0px 4px 16px var(--modal_overlay);
    padding: 0.5rem;
    justify-content: stretch;
    max-width: calc(100% - 2rem);
  }

  &__link {
    display: flex;
    padding: 8px;
    border-radius: 4px;
    background-color: var(--bg_containers) !important;

    &:not(:first-child) {
      margin-top: 16px;
    }
  }

  &__icon {
    margin-right: 0.25rem;
  }

  &__info {
    margin-right: 16px;
  }

  &__arrow {
    margin-left: auto;
  }
}
</style>
