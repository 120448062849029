export const token =
  'pk.eyJ1IjoiYXBsdXNzbW9zY293IiwiYSI6ImNqeXR6MGNyOTA4czEzY216NWM4MG9vZjcifQ.Ks7yZfpW4gW2LmlVYLHKgw'

export const baseLayers = [
  {
    label: 'Mapbox Basic',
    name: 'basic',
    link: 'mapbox://styles/mapbox/basic-v9'
  },
  {
    label: 'Mapbox Streets',
    name: 'streets',
    link: 'mapbox://styles/mapbox/streets-v9'
  },
  {
    label: 'Mapbox Bright',
    name: 'bright',
    link: 'mapbox://styles/mapbox/bright-v9'
  },
  {
    label: 'Mapbox Light',
    name: 'light',
    link: 'mapbox://styles/mapbox/light-v9'
  },
  {
    label: 'Mapbox Dark',
    name: 'dark',
    link: 'mapbox://styles/aplussmoscow/cl5jbvkfr001d14qehc4m9wkw'
  },
  {
    label: 'Mapbox Satellite',
    name: 'satellite',
    link: 'mapbox://styles/mapbox/satellite-v9'
  },
  {
    label: 'Mapbox Satellite Streets',
    name: 'satellite-streets',
    link: 'mapbox://styles/mapbox/satellite-streets-v9'
  }
]
