<template>
  <ul class="bottom-nav__container">
    <li
      v-for="navItem in navConfig"
      :key="navItem.route"
      class="bottom-nav__container--item"
      @click="clickHandler(navItem.route)">
      <r-icon-button
        :caption="navItem.name"
        :icon="navItem.icon"
        :active="navItem.active" />
    </li>
  </ul>
</template>

<script>
export default {
  data () {
    return {
      navConfig: [
        {
          route: 'object-list',
          active: false,
          name: 'Список объектов',
          icon: 'list'
        },
        {
          route: 'create',
          active: false,
          name: 'Добавить объект',
          icon: 'plus',
          centerButton: true
        },
        {
          route: 'settings',
          active: false,
          name: 'Меню',
          icon: 'mobile-menu'
        }
      ]
    }
  },
  watch: {
    $route (to, _) {
      this.activeToggler(to.name)
    }
  },
  methods: {
    clickHandler (route) {
      if (`/odd/${route}` !== this.$route.fullPath) {
        this.$router.push(`/odd/${route}`)
      }
    },
    activeToggler (route) {
      this.navConfig.forEach(i => (i.active = false))
      const currentRoute = this.navConfig.find(i => i.route === route)
      if (currentRoute) currentRoute.active = true
    }
  }
}
</script>
<style lang="scss">
.bottom-nav__container {
  position: fixed;
  bottom: 0.5rem;
  right: 0.5rem;
  left: 0.5rem;
  display: grid;
  z-index: 500;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  justify-content: space-between;
  justify-items: stretch;
  align-items: center;
  background-color: var(--bg_surface);
  overflow: hidden;
  border-radius: 0.25rem;
  box-shadow: var(--mid_shadow);

  &--item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .r-icon-button {
    justify-content: center;
    width: 100% !important;
    border-radius: 0;
  }
}
</style>
