import cloneDeep from 'lodash.clonedeep'
import throttle from 'lodash.throttle'
import debounce from 'lodash.debounce'
import turfLength from '@turf/length'
import { lineString } from '@turf/helpers'
// import { Constants } from '@/libs/map-draw/lib-utils'
import {
  getFeaturesByLayerId,
  createRouteHelper,
  createRoutePoints,
  getWaypointsQuery
} from '@/libs/map-draw/helpers'

const getNearestFeature = (pointFeatures, coords) => {
  let length = Infinity
  let feature = pointFeatures[0]

  for (let i = 0; i < pointFeatures.length; i++) {
    const point = pointFeatures[i]
    const line = lineString([coords, point.geometry.coordinates])
    const lineDistance = turfLength(line, { units: 'meters' })

    if (lineDistance < length) {
      length = lineDistance
      feature = point
    }
  }

  return feature
}

export const createODDDrawLinesMode = (MapboxDraw, map) => {
  const DrawLines = {}
  const customState = {
    currentPoint: null,
    routePoints: [],
    currentRouteCoordinates: [],
    fixedRouteCoordinates: [],
    lineRouteItems: [],
    isLastSP: false
  }

  const clearCustomState = () => {
    customState.routePoints = []
    customState.currentPoint = null
    customState.currentRouteCoordinates = []
    customState.fixedRouteCoordinates = []
    customState.lineRouteItems = []
    customState.isLastSP = false
  }
  const showError = throttle(() => {
    map.$message({
      message:
        'Не удается построить дальнейший маршрут из последней указанной точки',
      type: 'error',
      duration: 1500,
      customClass: 'route-editor-warning'
    })
  }, 1000)

  const { model, editorState, books } = map.$store.state
  const { ids } = model
  const { eventClasses } = books

  map.helpers.clearCustomState = clearCustomState

  DrawLines.makeRoutingRequest = debounce(async function (currentPoints, state) {
    try {
      const locString = getWaypointsQuery(cloneDeep(currentPoints))
      const url = `routing/${model.id}/${locString}?postprocessing=line_route_item`

      const { data } = await map.$store.dispatch('ROUTE_REQUEST', {
        url
      })
      const { geometry } = data

      customState.currentRouteCoordinates = cloneDeep(geometry.coordinates)

      createRouteHelper(this.map, geometry.coordinates)

      const createdObject = {
        type: 'Feature',
        properties: {
          name: 'Новое перекрытие',
          event_class_id: eventClasses[0].id,
          start_time: null,
          end_time: null
        },
        geometry
      }
      map.$store.commit('SET', ['editorState.createdObject', createdObject])

      // this.finishRoute(state)
    } catch (error) {
      console.log(error)
      showError(map)
    }
  }, 100)

  // DrawLines.finishRoute = function (state) {
  //   const { currentRouteCoordinates } = customState

  //   editorState.isDrawing = false

  //   const newCoordinates = currentRouteCoordinates

  //   state.line.setCoordinates(newCoordinates)
  //   clearCustomState()

  //   // set properties
  //   state.line.setProperty('name', 'Новое перекрытие')
  //   state.line.setProperty('event_class_id', eventClasses[0].id)
  //   state.line.setProperty('start_time', null)
  //   state.line.setProperty('end_time', null)

  //   this.map.fire(Constants.events.CREATE, {
  //     features: [state.line.toGeoJSON()]
  //   })

  //   // set mode
  //   this.changeMode(Constants.modes.SIMPLE_SELECT, {
  //     featureIds: [state.line.id]
  //   })

  //   map.$store.commit('SET', ['editorState.mode', 'select'])
  // }

  DrawLines.clickAnywhere = function (state, e) {
    const { lng, lat } = e.lngLat
    const stopPointFeatures = getFeaturesByLayerId(this.map, e, ids.stop_points)
    const nodesFeatures = getFeaturesByLayerId(this.map, e, ids.nodes)
    const pointFeatures = [...stopPointFeatures, ...nodesFeatures]
    const { routePoints } = customState

    if (!pointFeatures.length) return

    if (routePoints.length === 0) editorState.isDrawing = true

    const feature = getNearestFeature(pointFeatures, [lng, lat])
    const pointId = feature.properties.id
    const pointCoordinates = feature.geometry.coordinates

    routePoints.push({ coordinates: pointCoordinates, id: pointId })
    createRoutePoints(
      this.map,
      routePoints.map(c => c.coordinates)
    )

    this.updateUIClasses({ mouse: 'add' })

    if (routePoints.length > 1) {
      const currentPoints = [
        ...cloneDeep(routePoints),
        cloneDeep({
          coordinates: feature.geometry.coordinates,
          id: feature.properties.id
        })
      ]
      this.makeRoutingRequest(currentPoints, state)
    }
  }

  return { ...MapboxDraw.modes.draw_line_string, ...DrawLines }
}
