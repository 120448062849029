<template>
  <div class="event-attributes">
    <component
      :is="infoType"
      v-if="event"
      :related="related"
      :source="event"
      @updateRelated="loadRelated" />
    <files-comments
      v-if="$route.name === 'odd'"
      :id="object.id"
      :source_id="sourceId"
      closed
      wrap
      column />
  </div>
</template>

<script>
import rModalCardBlock from '@/components/globals/r-modal-elements/r-modal-card-block'
import related from './config/related'

export default {
  components: {
    rModalCardBlock,
    filesComments: () => import('@/components/files-comments/files-comments'),
    accident: () => import('./accident'),
    closure: () => import('./closure')
  },
  props: {
    object: {
      type: Object,
      required: true
    },
    geometryType: {
      type: String,
      default: 'Point'
    }
  },
  data() {
    return {
      related,
      showNotify: false,
      showSigns: false
    }
  },
  computed: {
    infoType() {
      const typeName = this.eventClasses.find(
        ({ id }) => id === this.object.event_class_id
      )?.name

      switch (typeName) {
        case 'ДТП':
          return 'accident'
        default:
          return 'closure'
      }
    },
    event() {
      return this.$store.state.featureToEdit?.properties
    },
    sourceId() {
      return this.$store.state.model.ids.events
    },
    eventClasses() {
      return this.$store.state.books.eventClasses
    },
    signIcons() {
      return this.$store.state.books.signIcons
    }
  },
  mounted() {
    this.loadRelated()
  },
  methods: {
    async loadRelated() {
      this.loading = true

      for (const r in this.related) {
        const urlName = this.related[r].urlName
        const source_id = this.$store.state.services[urlName]
        if (!source_id) return

        try {
          const config = JSON.stringify(this.related[r].config)
          const url = `objectInfo/${source_id}?config=${config}`

          const { data } = await this.$store.dispatch('GET_REQUEST', {
            url
          })

          this.related[r].data = Object.values(data)
        } catch (e) {
          console.log(e)
        }
      }
      this.loading = false
    }
  }
}
</script>

<style lang="scss">
.event-attributes {
  display: grid;
  grid-gap: 1rem;

  .files-comments {
    padding-top: 1rem;
    border-top: 1px solid var(--dividers_low_contrast);
  }
}
</style>
