<template>
  <div class="odd">
    <map-gl v-loading="iconsLoading" />
    <navbar />
    <card
      v-if="cardId"
      v-show="!vehicleCreating" />
    <vehicle-create v-if="vehicleCreating" />

    <router-view />
  </div>
</template>

<script>
import mapGl from '@/components/map/map'
import navbar from '@/components/navbar/navbar'
import Card from '@/modules/odd/card/card'
import vehicleCreate from '@/modules/odd/card/accident/vehicle-create'

export default {
  components: {
    navbar,
    mapGl,
    Card,
    vehicleCreate
  },
  computed: {
    cardId () {
      return this.$store.state.cardId
    },
    vehicleCreating () {
      return this.$store.state.vehicleCreating
    },
    iconsLoading() {
      return this.$store.state.iconsLoading
    }
  },
  methods: {
    goToDefaultPath () {
      if (this.$router.currentRoute.path !== this.$store.state.defaultPath) {
        this.$router.push({ path: this.$store.state.defaultPath })
      }
    }
  }
}
</script>

<style lang="scss">
.odd {
  display: grid;
  width: 100%;
  height: 100%;
}
</style>
