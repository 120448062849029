<template>
  <div class="r-simple-card">
    <div
      :class="['r-simple-card__title-block', { noToggler }]"
      @click="toggleHandler()">
      <r-title type="subtitle-2">
        {{ title }}
      </r-title>
      <div
        v-if="!noToggler"
        class="r-simple-card__title-block__toggler">
        <r-icon
          :name="isOpen ? 'chevron-up' : 'chevron-down'"
          :size="20" />
      </div>
    </div>
    <transition
      name="fadeHeight"
      mode="out-in">
      <div
        v-if="isOpen"
        class="r-simple-card__content">
        <slot />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null
    },
    noToggler: {
      type: Boolean,
      default: false
    },
    defaultClosed: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isOpen: this.noToggler || !this.defaultClosed
    }
  },
  methods: {
    toggleHandler () {
      if (this.noToggler) return

      this.isOpen = !this.isOpen
    }
  }
}
</script>

<style lang="scss">
.r-simple-card {
  position: relative;
  padding: 8px;
  background-color: var(--bg_containers) !important;
  border-radius: var(--border_radius);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: calc(100%);
  height: auto;
  &:last-child {
    margin-bottom: 0;
  }
  &__title-block {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    .r-text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: calc(100% - 24px);
      display: block !important;
    }
    &.noToggler {
      cursor: default;
    }
  }
  &__content {
    width: 100%;
    margin-top: 8px;
  }
}
</style>
