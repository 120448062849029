<template>
  <div class="sign-attributes">
    <r-tip
      v-if="isRackFull"
      type="warning">
      На стойке нету свободных мест для знаков
    </r-tip>
    <r-button
      icon="add-plus"
      :disabled="isRackFull"
      @click="$emit('add-sign')">
      Добавить знак
    </r-button>
    <sign-item
      v-for="sign in signs"
      :key="sign.id"
      :sign="sign"
      @delete-sign="$emit('remove-sign', $event)" />
    <files-comments
      v-if="$route.name === 'odd-objects'"
      :id="object.id"
      :source_id="sourceId"
      class="sign-attributes__row"
      wrap
      closed
      container
      column />
  </div>
</template>

<script>
import signItem from './sign-item'
import filesComments from '@/components/files-comments/files-comments'

export default {
  components: {
    filesComments,
    signItem
  },
  props: {
    object: {
      type: Object,
      required: true
    },
    signs: {
      type: Array,
      required: true
    }
  },
  computed: {
    sourceId() {
      return this.$store.state.model.ids.signs
    },
    isRackFull() {
      return this.signs?.length >= 5
    },
    signIcons() {
      return this.$store.state.books.signIcons
    },
    racks() {
      return this.$store.state.racks
    }
  }
}
</script>

<style lang="scss">
.sign-attributes {
  display: grid;
  grid-gap: 1rem;

  .r-tip {
    position: relative;
    display: flex;
    align-items: center;
    border-radius: var(--border-radius);
    background-color: var(--accent_selected);
    overflow: hidden;
    padding: 8px;
    width: 100%;

    .r-icon {
      margin-right: 8px;
      flex-shrink: 0;
    }
  }
}
</style>
