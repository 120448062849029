export const editorState = {
  type: null,
  mode: 'create',
  step: 0,
  id: null,
  layerId: null,
  geometry: null,
  properties: null,
  isDrawing: false,
  createdObjects: [],
  createdObject: null,
  featureToDelete: null
}
