<template>
  <r-bottom-sheet
    ref="objectList"
    :overlay="false"
    class="objectList-card"
    max-height="100%"
    initial-height="100%"
    title="Список объектов"
    :rounded="false"
    close-button
    @closed="close">
    <div class="odd-objects">
      <r-tabs
        v-show="!creating"
        v-model="tab">
        <div class="odd-objects__filters">
          <r-search-input
            :filter-text="filterText"
            @change="value => changeHandler(value, 'filterText')" />
          <r-button
            icon="filter"
            :active="isFiltersVisible || hasFilters"
            type="secondary"
            @click="toggleFilters" />
          <object-filter
            v-if="isFiltersVisible"
            :tab="tab"
            :active="isFiltersVisible" />
        </div>
        <r-tab
          id="signs"
          active
          name="Дорожные знаки">
          <object-list
            v-if="signs.length"
            :objects="signs"
            type="signs"
            class="odd-objects__list" />
          <r-text
            v-else
            type="caption">
            Список пуст
          </r-text>
        </r-tab>
        <r-tab
          id="road_blocks"
          name="Перекрытия">
          <object-list
            v-if="events.length"
            :objects="events"
            type="events"
            class="odd-objects__list" />
          <r-text
            v-else
            type="caption">
            Список пуст
          </r-text>
        </r-tab>
      </r-tabs>
    </div>
  </r-bottom-sheet>
</template>

<script>
import objectList from '@/modules/odd/list/object-list'
import objectFilter from '@/modules/odd/filter/object-filter'
export default {
  components: {
    objectList,
    objectFilter
  },
  data () {
    return {
      tab: 'road_blocks',
      filterText: '',
      isFiltersVisible: false
    }
  },
  computed: {
    creating () {
      return this.$route.name === 'odd-create'
    },
    signs () {
      return this.$store.state.lists.signs.filter(s =>
        s.name
          ?.trim()
          .toLowerCase()
          .includes(this.filterText.trim().toLowerCase())
      )
    },
    events () {
      return this.$store.state.lists.events.filter(s =>
        s.name
          ?.trim()
          .toLowerCase()
          .includes(this.filterText.trim().toLowerCase())
      )
    },
    eventsFilter () {
      return this.$store.state.eventsFilter || {}
    },
    showSings() {
      return this.$store.state.showSings
    },
    showEvents() {
      return this.$store.state.showEvents
    },
    hasFilters () {
      const { category, interval } = this.eventsFilter
      if (!this.showSings || !this.showEvents) return true
      if ((category && category !== 'Все') || interval?.active) return true
      return false
    }
  },
  mounted () {
    this.$refs.objectList.open()
  },
  methods: {
    toggleFilters () {
      this.isFiltersVisible = !this.isFiltersVisible
    },
    close () {
      if (this.$route.name !== 'odd') this.$router.push('/odd')
    },
    changeHandler (value, field) {
      this[field] = value
    }
  }
}
</script>

<style lang="scss">
.odd-objects {
  display: grid;
  padding: 0.5rem;
  grid-gap: 0.5rem;
  overflow: hidden;
  height: 100%;
  position: relative;
  align-content: start;

  &__filters {
    display: grid;
    grid-gap: 0.25rem;
    grid-template-columns: 1fr auto;
    grid-template-areas: 'input btn' 'filters filters';

    .r-search-input {
      grid-area: input;
    }

    .r-button {
      grid-area: btn;
    }

    .object-filter {
      grid-area: filters;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
  }
}
</style>
