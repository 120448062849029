<template>
  <div class="checklist-inspection">
    <r-title
      v-if="data.title"
      class="checklist-inspection__header"
      color-type="subhead">
      {{ data.title }}
    </r-title>
    <div class="checklist-inspection__captions">
      <r-title type="subtitle-1">
        {{ data.radioButtonHeaders ? data.radioButtonHeaders.true : `Есть` }}
      </r-title>
      <r-title type="subtitle-1">
        {{
          data.radioButtonHeaders
            ? data.radioButtonHeaders.false
            : `Отсутствует`
        }}
      </r-title>
    </div>
    <div class="checklist-inspection__content">
      <checklist-inspection-list
        v-for="list in data.lists"
        :key="list.id"
        :source="source"
        :docs="docs"
        :list="list" />
    </div>
  </div>
</template>

<script>
import checklistInspectionList from './checklist-inspection-list'

export default {
  components: { checklistInspectionList },
  props: {
    source: {
      type: Object,
      required: true
    },
    docs: {
      type: Array,
      required: true
    },
    data: {
      type: Object,
      required: true,
      default: () => null
    }
  }
}
</script>

<style lang="scss" scoped>
.checklist-inspection {
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: 1fr 1fr;

  &__content {
    border-radius: var(--border_radius);
    border: 1px solid var(--field_border);
    display: grid;
    padding: 0.5rem 1rem;
    grid-column: 1 / 3;
    grid-gap: 1rem;
  }

  &__header {
    grid-column: 1 / 3;
  }

  &__captions {
    padding: 0 1rem 0 0.5rem;
    grid-column: 2 / 3;
    display: grid;
    grid-auto-flow: column;
    justify-content: center;
    grid-gap: 0.5rem;

    .r-title {
      width: 6rem;
      text-align: center;
    }
  }
}
</style>
