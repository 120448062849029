import cloneDeep from 'lodash.clonedeep'
import { editorState } from '@/config/editor'

const services = {
  systemFields: [
    'id',
    'created_at',
    'updated_at',
    'created_by',
    'updated_by',
    'disabled'
  ],
  vehicles: 'f09e59fb-ee58-482c-8b0f-9ac520cf2099',
  mainContractor: 'ef021b44-a650-478e-a25f-ace1e9b545ca',
  vehicleTypes: 'aaf863b8-3df0-4264-9b65-773fee7aaeef',
  vehicleGroups: 'b549d7d6-9e06-49e0-9352-eddd8ecee923',
  vehicleModels: '4f172a69-d656-4a36-8673-375fa04da821',
  vehicleCategories: 'c3e5d963-f252-43e9-8857-e20bc39cb64e',
  geozones: 'bfbc88aa-6085-4a9e-9be2-c288c579c494',
  vehicleType: 'aaf863b8-3df0-4264-9b65-773fee7aaeef',
  applicationsStatusList: '11bed2e0-be6c-4ae3-9476-9334b76296fd',
  employees: 'a28c528a-64dd-41f9-94b9-aefd7485f55c',
  checkpoints: 'c3c87a16-85d0-4160-8101-cedab2440e56',
  warehouses: '30cd86ef-4c58-44ee-ab31-6f34f1c327b2',
  potStatusList: '1d5f5799-6371-4c33-b741-e517d5676fb0',
  statusPreId: 'faee0926-9f71-4f37-8524-5199b2787201',
  subprojects: 'fe1656be-32eb-40e4-97f4-835fcd6ded4a',
  accident_categories: '705c6f35-dd0f-4d5b-a536-b7b0ce45b50b',
  accident_classifications: 'fd6ebfcf-ebf9-46d7-aeba-43172f6ec0c4',
  trafficaccident_types: '2ee43c9b-40ee-496d-8f4f-43fe19ae2085',
  injureds: '11cb459b-0cf3-412f-8feb-f4dc4a444a23',
  trafficaccident: '4877c7e5-d3e8-494a-9376-1f8ce6b363d5',
  road_block: '2ec6a5db-5420-4288-9ca9-9b26b85a07dd',
  three_vio: '1f702ed3-b18d-4280-8867-2ac2addefe23'
}

const systemFields = [
  'id',
  'created_at',
  'updated_at',
  'created_by',
  'updated_by',
  'disabled'
]

const model = {
  id: null,
  name: null,
  ids: {},
  children: []
}

export default {
  logs: [],
  darkTheme: true,
  defaultPath: '/',
  datasources: {},
  books: {
    signIcons: [],
    eventClasses: []
  },
  lists: {
    signs: [],
    events: []
  },
  model,
  initialState: {
    zoom: 11,
    center: [128.128904931864, 51.4005501918081],
    baseLayer: 'dark'
  },
  flyToGeom: null,
  loadingLayers: [],
  iconsLoading: false,
  cardId: null,
  cardType: null,
  newCardId: null,
  editorState: cloneDeep(editorState),
  featureToEdit: null,
  needToUpdate: false,
  needToRemove: false,
  vehicleCreating: false,
  services,
  systemFields,
  eventsFilter: {
    category: null,
    interval: null
  },
  showEvents: true,
  showSings: true,
  racks: null,
  modelLoaded: false
}
