var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"create-type-select"},[_c('r-tabs',{model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}},_vm._l((_vm.types),function(ref){
var id = ref.id;
var label = ref.label;
var icon = ref.icon;
return _c('r-tab',{key:id,attrs:{"id":id,"icon":icon,"name":label}})}),1),(_vm.type === 'events')?_c('r-text',{attrs:{"center":""}},[_vm._v(" Для этого объекта нужно выбрать тип геометрии ")]):_vm._e(),(_vm.type === 'events')?_c('r-tabs',{model:{value:(_vm.geomType),callback:function ($$v) {_vm.geomType=$$v},expression:"geomType"}},_vm._l((_vm.geomTypes),function(ref){
var id = ref.id;
var label = ref.label;
var icon = ref.icon;
return _c('r-tab',{key:id,attrs:{"id":id,"icon":icon,"name":label}})}),1):_vm._e(),_c('r-button',{staticClass:"create-type-select__footer",attrs:{"type":"primary"},on:{"click":_vm.clickHandler}},[_vm._v(" Продолжить ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }