<template>
  <div :class="[`r-icon ${colorType}`]">
    <component
      :is="`icon-${name}`"
      :width="size"
      :height="size"
      :class="[`r-icon__svg ${type}`]"
      :style="getStyle"
      role="presentation" />
    <div
      v-if="arrowDown"
      class="r-icon-arrow-down"
      :style="getArrowStyle" />
  </div>
</template>

<script>
import { iconsBank } from './index'

const iconTypes = ['primary', 'regular', 'secondary', 'additional']

export default {
  props: {
    name: {
      validator: function (val) {
        return iconsBank.indexOf(val) !== -1
      },
      required: true,
      type: String
    },
    type: {
      validator: val => iconTypes.indexOf(val) !== -1,
      default: 'primary',
      type: String
    },
    size: {
      type: Number,
      default: null
    },
    color: {
      type: String,
      default: null
    },
    arrowDown: { type: Boolean, default: false },
    colorType: {
      type: String,
      default: ''
    }
  },
  computed: {
    getStyle () {
      const styles = {}

      if (this.color) styles.fill = `${this.color} !important`
      if (this.size) {
        styles.width = `${this.size}px`
        styles.height = `${this.size}px`
      }

      return styles
    },
    getArrowStyle () {
      const styles = {}

      if (this.color) styles.background = `${this.color} !important`

      return styles
    }
  }
}
</script>

<style lang="scss">
.r-icon {
  position: relative;
  max-width: 100%;
  max-height: 100%;
  height: 100%;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  &__svg {
    width: 100%;
    height: 100%;
    transition: all 0.15s ease;
    fill: var(--icons_high_contrast) !important;
  }

  &-arrow-down {
    margin-left: 4px;
    width: 8px;
    height: 4px;
    clip-path: polygon(0 0, 50% 100%, 100% 0);
    background-color: var(--icons_high_contrast) !important;
  }

  &.error {
    .r-icon__svg {
      fill: var(--accent_error) !important;
    }
  }

  &.warning {
    .r-icon__svg {
      fill: var(--accent_warning) !important;
    }
  }

  &.accent-primary {
    .r-icon__svg {
      fill: var(--accent_primary) !important;
    }
  }

  &.white {
    .r-icon__svg {
      fill: var(--white_white) !important;
    }
  }
}
</style>
