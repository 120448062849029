import Vue from 'vue'
import Vuex from 'vuex'

import actions from './main/actions'
import mutations from './main/mutations'
import state from './main/state'

import bdd from './modules/bdd'
import violations from './modules/violations'

Vue.use(Vuex)

export default new Vuex.Store({
  state,
  mutations,
  actions,
  modules: {
    bdd,
    violations
  }
})
