import vue from 'vue'

const state = {
  applicationsSourceId: 'e01d9bf1-4933-4e13-94ce-66de510916f4',
  applicationsUrl: 'e01d9bf1-4933-4e13-94ce-66de510916f4',
  applicationsStatusListSourceId: '11bed2e0-be6c-4ae3-9476-9334b76296fd',
  statusesUrl: '11bed2e0-be6c-4ae3-9476-9334b76296fd',
  checklistsUrl: '4afc59af-901b-4c34-9d00-1ef5418aee16',
  checklistAttrsUrl: 'd8259144-aa56-4900-aed7-b50fc82a410d',
  checklistAttrsGroupUrl: '9c71cfad-552c-4f50-83cf-9b0467c5336d',
  checklistAttrsValuesUrl: '4944de74-d4c2-449f-b17e-7b4790f6cb01',
  statuses: null,
  selectModalShow: false,
  selectModalAttrId: null,
  selectModalAttrName: null,
  confirmModalShow: false,
  updateAlbum: false,
  albumResources: null
}

const mutations = {
  BDD_SET_FIELD (state, { field, value }) {
    vue.set(state, field, value)
  }
}

export default {
  state,
  mutations
}
