export * from './fields'
export * from './related'

export const config = {
  include: {
    violation_representatives_employees: {},
    violation_appointed_employees: {},
    detection_employee: { only: ['name'] },
    responsible: {},
    vehicle: {
      only: ['reg_number'],
      include: {
        model: {
          only: ['id', 'name']
        },
        vehicle_type: {
          only: ['id', 'name']
        }
      }
    }
  },
  order: { created_at: 'desc' }
}

export const accidentTypes = [
  'Нарушение при управлении ТС задним ходом',
  'Превышение скорости',
  'Нарушение правил перевозки груза',
  'Техническая неисправность',
  'Правила пользования внешних световых приборов',
  'Нарушение требований дорожных знаков/разметки',
  'Нарушение пешеходами',
  'Алкогольное опьянение',
  'Прочие нарушения'
]
