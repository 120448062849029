let BASE_URL

switch (process.env.NODE_ENV) {
  case 'development':
    BASE_URL = 'https://dmtlp.zone-it.studio'
    break
  default:
    BASE_URL = ''
    break
}

export default {
  BASE_URL: BASE_URL,
  URL: `${BASE_URL}/rest`
}
