import axios from 'axios'
import constants from '@/constants/url'
import { getHeaders } from '@/utils'

export default {
  GET_REQUEST ({ state }, { url }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${constants.URL}/${url}`, {
          headers: getHeaders()
        })
        .then(data => resolve(data))
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  },
  LOGIN({ state }, { url }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${constants.URL}/${url}`, {})
        .then(data => resolve(data))
        .catch(error => {
          if (error.response.status === 411) {
            resolve(error.response)
          } else {
            reject(error)
          }
        })
    })
  },
  POST_REQUEST ({ state }, { url, data }) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${constants.URL}/${url}`, data, {
          headers: getHeaders()
        })
        .then(data => resolve(data))
        .catch(error => {
          reject(error)
        })
    })
  },
  PUT_REQUEST ({ state }, { url, data }) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${constants.URL}/${url}`, data, {
          headers: getHeaders()
        })
        .then(data => resolve(data))
        .catch(error => {
          reject(error)
        })
    })
  },
  DELETE_REQUEST ({ state }, { url }) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${constants.URL}/${url}`, {
          headers: getHeaders()
        })
        .then(data => resolve(data))
        .catch(error => {
          reject(error)
        })
    })
  },
  ROUTE_REQUEST ({ state }, options) {
    // GET REQUEST (options - url)
    return new Promise((resolve, reject) => {
      const url = `${constants.BASE_URL}/osrm/${options.url}`
      axios
        .get(url, {
          headers: getHeaders()
        })
        .then(data => resolve(data))
        .catch(error => {
          reject(error)
        })
    })
  }
}
