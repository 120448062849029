<template>
  <img
    :src="imageSrc"
    alt="no-data"
    :style="styles">
</template>

<script>
import constants from '@/constants/url'
import { getHeaders } from '@/utils'

export default {
  props: {
    resource_id: {
      type: String,
      required: true
    },
    size: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      baseURL: constants.URL,
      downloadURL: 'download_resource',
      imageSrc: null
    }
  },
  computed: {
    styles() {
      const styles = {}

      if (this.size) {
        styles.width = this.size + 'px'
        styles.height = this.size + 'px'
      }

      return styles
    }
  },
  async created() {
    if (!this.resource_id) return

    const url = `${this.baseURL}/${this.downloadURL}/${this.resource_id}`

    const res = await fetch(url, { headers: getHeaders() })
    const blob = await res.blob()

    this.imageSrc = await URL.createObjectURL(blob)
  }
}
</script>
