<template>
  <r-bottom-sheet
    ref="card"
    :overlay="false"
    initial-height="50%"
    max-height="100%"
    :title="title"
    close-button
    @closed="closeCard">
    <div
      v-loading="loading"
      class="odd-card">
      <card-controls
        :saving="saving"
        :has-rack="hasRack"
        @open-rack="openRack"
        @close="closeCard"
        @save="saveObject"
        @delete="deleteObject"
        @flyTo="flyTo" />
      <div class="odd-card__attributes">
        <sign-item
          v-if="cardType === 'signs'"
          no-bg
          :sign="object" />
        <event-attributes
          v-else-if="cardType === 'events'"
          :object="object"
          @save="saveObject" />
      </div>
    </div>
  </r-bottom-sheet>
</template>

<script>
import CardControls from './card-controls'
import SignItem from './sign-item'
import EventAttributes from './event-attributes'

import { notifyFactory } from '@/utils'

export default {
  components: {
    CardControls,
    SignItem,
    EventAttributes
  },
  data () {
    return {
      loading: false,
      saving: false,
      object: {}
    }
  },
  computed: {
    hasRack() {
      return !!(this.cardType === 'signs' && this.object?.rack_id)
    },
    cardId () {
      return this.$store.state.cardId
    },
    cardType () {
      return this.$store.state.cardType
    },
    title () {
      return this.object.name || 'Карточка объекта'
    }
  },
  watch: {
    '$store.state.needToSave' (value) {
      if (value) this.saveObject()
    },
    cardId (val) {
      if (val) {
        this.loadData()
      }
    }
  },
  created () {
    this.loadData()
  },
  mounted () {
    this.$refs.card.open()
  },
  methods: {
    async loadData () {
      const cardId = String(this.cardId)
      try {
        this.loading = true

        const { ids } = this.$store.state.model

        this.$store.commit('SET', ['editorState.layerId', ids[this.cardType]])
        this.$store.commit('SET', ['editorState.id', this.cardId])

        const config = {
          where: [
            {
              field: 'id',
              value: this.cardId,
              op: '='
            }
          ],
          include: {
            event_class: { only: ['name'] },
            road_block: {
              include: {
                recommendations: {}
              }
            },
            trafficaccident: {
              include: { injureds: {}, intruders: {}, corrective_actions: {} }
            }
          }
        }
        const url = `objectInfo/${ids[this.cardType]}?config=${JSON.stringify(
          config
        )}`
        const { data } = await this.$store.dispatch('GET_REQUEST', { url })

        const object = data[cardId]

        this.$set(this, 'object', object)

        this.$store.commit('SET', [
          'featureToEdit',
          {
            id: this.cardId,
            properties: object,
            geometry: object.geom
          }
        ])
      } catch (e) {
        throw new Error(e)
      } finally {
        this.loading = false
      }
    },
    getSavingData () {
      const { featureToEdit } = this.$store.state

      if (this.cardType === 'signs') {
        return {
          ...this.object,
          projection: featureToEdit.properties.projection,
          geom: featureToEdit.geometry
        }
      }
      // Fix this with backend
      if (this.object.trafficaccident) {
        this.object.trafficaccident.datetime = this.$rDate.format(
          this.object.start_time,
          'iso'
        )
      }
      // Fix this with backend

      return {
        ...this.object,
        start_time: this.$rDate.format(this.object.start_time, 'iso'),
        end_time: this.$rDate.format(this.object.end_time, 'iso'),
        geom: featureToEdit.geometry
      }
    },
    async saveObject () {
      try {
        this.saving = true

        const { model } = this.$store.state
        const { ids } = model
        const url = `objectInfo/${ids[this.cardType]}`
        const data = this.getSavingData()

        await this.$store.dispatch('POST_REQUEST', { url, data })

        const title = 'Сохранение выполнено'
        const message = 'Объект успешно сохранен'
        this.$notify(notifyFactory('succcess', title, message))

        this.$store.commit('SET', ['needToUpdate', true])

        this.closeCard()
      } catch (e) {
        const title = 'Сохранение не выполнено'
        const message = 'Не удалось сохранить объект'
        this.$notify(notifyFactory('error', title, message))
        throw new Error(e)
      } finally {
        this.saving = false
        this.$store.commit('SET', ['needToSave', false])
      }
    },
    async deleteObject () {
      try {
        const { ids } = this.$store.state.model
        const url = `objectInfo/${ids[this.cardType]}?id=${this.cardId}`
        await this.$store.dispatch('DELETE_REQUEST', { url })

        this.$store.commit('SET', ['needToUpdate', true])
        this.closeCard()
      } catch (e) {
        throw new Error(e)
      }
    },
    flyTo () {
      this.$store.commit('SET', ['flyToGeom', this.object.geom])
    },
    closeCard () {
      this.$store.commit('SET', ['cardId', null])
    },
    openRack() {
      const feature = {
        id: this.object.id,
        geometry: this.object.geom,
        rack: this.object.rack_id,
        properties: this.object
      }
      const editorState = {
        id: null,
        layerId: null,
        step: 2,
        type: 'signs',
        createdObject: feature
      }
      this.$store.commit('SET', ['editorState', editorState])

      this.$router.push('/odd/create')
    }
  }
}
</script>

<style lang="scss">
.odd-card {
  height: 100%;
  display: grid;
  overflow: hidden;

  &__attributes {
    height: 100%;
    overflow-y: auto;
    padding: .5rem;
  }
}
</style>
