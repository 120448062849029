<template>
  <div class="violations">
    <div class="violations-header">
      <r-title>Реестр нарушений</r-title>
    </div>
    <r-search-input
      placeholder="Поиск по актам и мерам"
      :filter-text="filterText"
      @change="searchChange" />
    <div class="violations-list">
      <router-link
        v-for="violation in filteredViolations"
        :key="violation.id"
        class="violations-list__item"
        :to="`/violations/edit?id=${violation.id}`">
        <r-title type="title-2">
          {{ violation.type }}
        </r-title>
        <r-text type="caption">
          {{ `Дата фиксации: ${violation.datetime}` }}
        </r-text>
        <r-text type="caption">
          {{ `ФИО нарушителя: ${violation.guilty_name}` }}
        </r-text>
        <r-icon
          name="chevron-right"
          :size="24" />
      </router-link>
    </div>
  </div>
</template>

<script>
import { config } from './config'
import { fullDateWithDot, sortDataByField } from '@/utils'

export default {
  data () {
    return {
      filterText: '',
      activeFilterTab: 'notCompleted',
      violations: [],
      loading: false
    }
  },
  computed: {
    filteredViolations () {
      const searchFields = [
        'violation_act_no',
        'assignment_act_no',
        'description'
      ]
      const query = this.filterText?.trim()?.toLowerCase()
      if (!query) return this.formatted || []

      return this.formatted.filter(e => {
        const fields = searchFields
          .map(f => e[f])
          .join('')
          ?.trim()
          .toLowerCase()
        return fields.includes(query)
      })
    },
    source_id () {
      return this.$store.state.violations.source_id
    },
    related () {
      return this.$store.state.violations.related
    },
    formatted () {
      return this.violations.map(v => {
        const getRelatedValue = source => {
          const field = `${source}_id`
          return (
            this.related?.[source].data.find(d => d.id === v[field])?.name || '—'
          )
        }
        return {
          ...v,
          datetime: fullDateWithDot(v.datetime),
          type: getRelatedValue('type'),
          status: getRelatedValue('status')
        }
      })
    }
  },
  created () {
    this.load()
  },
  methods: {
    searchChange (e) {
      this.filterText = e
    },
    async load () {
      try {
        const url = `objectInfo/${this.source_id}?config=${JSON.stringify(
          config
        )}`

        const { data } = await this.$store.dispatch('GET_REQUEST', {
          url
        })

        this.violations = sortDataByField(
          Object.values(data),
          'datetime',
          'reverse'
        )
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>

<style lang="scss">
.violations {
  display: grid;
  grid-auto-flow: row;
  align-items: start;
  width: 100%;
  height: 100%;
  overflow: hidden;
  justify-content: stretch;
  position: relative;
  align-content: start;
  background-color: var(--bg_panel_primary);

  &-list {
    overflow: auto;
    padding: 0.5rem 0;
    height: 100%;
    display: grid;
    grid-auto-flow: row;

    &__item {
      display: grid;
      grid-auto-flow: row;
      grid-gap: 0.25rem;
      padding: 0.5rem;
      position: relative;

      &:not(:last-child) {
        border-bottom: 1px solid var(--dividers_low_contrast);
      }

      .r-icon {
        position: absolute;
        top: 50%;
        right: 1rem;
        transform: translate(0, -50%);
      }
    }
  }

  &-header {
    text-align: center;
    padding: 0.5rem;
    width: 100%;
  }
}
</style>
