import cloneDeep from 'lodash.clonedeep'
import { editorState } from '@/config/editor'

export default {
  TOGGLE (state, entity) {
    state[`${entity}`] = !state[`${entity}`]
  },
  LOG (state, data) {
    const date = String(new Date().toTimeString())
    state.logs.push({ data, date })
  },
  SET (state, [entity, value]) {
    if (entity.includes('.')) {
      const subEntity = entity.split('.')
      state[subEntity[0]][subEntity[1]] = value
    } else {
      state[entity] = value
    }
  },
  SYNC_STORAGE (state) {
    const initialState = JSON.stringify(state.initialState)
    localStorage.setItem('initialState', initialState)
    localStorage.setItem('darkTheme', state.darkTheme)
  },
  SET_DATASOURCE_FROM_TREE (state, { data, id }) {
    state.datasources[id] = data
  },
  SET_MODEL (state, { id, name, ids, children }) {
    state.model.id = id
    state.model.name = name
    state.model.ids = ids
    state.model.children = children
  },
  SET_LIST (state, { type, list }) {
    state.lists[type] = list
  },
  SET_BOOK (state, { type, book }) {
    state.books[type] = book
  },
  ADD_LOADING_LAYER (state, id) {
    if (state.loadingLayers.indexOf(id) === -1) state.loadingLayers.push(id)
  },
  REMOVE_LOADING_LAYER (state, id) {
    state.loadingLayers.splice(state.loadingLayers.indexOf(id), 1)
  },
  // editor
  SET_EDITOR_STATE (state, info) {
    Object.keys(info).forEach(key => {
      state.editorState[key] = info[key]
    })
  },
  SET_EDITOR_PROP (state, { field, value }) {
    state.editorState[field] = value
  },
  CLEAR_EDITOR_STATE (state) {
    Object.keys(editorState).forEach(key => {
      state.editorState[key] = cloneDeep(editorState[key])
    })
  },
  SET_CREATED_OBJECT (state, object) {
    state.editorState.createdObject = object
  }
}
