import { mapColors } from '@/config/colors'

const iconSizeValue = [
  'interpolate',
  ['linear'],
  ['zoom'],
  0,
  0.2,
  14,
  0.3,
  18,
  0.6
]

const iconSizeValue2 = [
  'interpolate',
  ['linear'],
  ['zoom'],
  0,
  0.3,
  14,
  0.4,
  18,
  0.6
]

const iconOffsetValue = [
  'interpolate',
  ['linear'],
  ['number', ['get', 'position']],
  1,
  ['literal', [0, -30]],
  2,
  ['literal', [40, -30]],
  3,
  ['literal', [80, -30]],
  4,
  ['literal', [120, -30]],
  5,
  ['literal', [160, -30]]
]

const eventIconImage = [
  'case',
  [
    '==',
    ['get', 'event_class_name'],
    'Недостатки'
  ],
  'event-icon',
  [
    '==',
    ['get', 'event_class_name'],
    'Ремонтные работы'
  ],
  'work-icon',
  [
    '==',
    ['get', 'event_class_name'],
    'ДТП'
  ],
  'accident-icon',
  'event-icon'
]

export const layersConfig = {
  racks: {
    type: 'circle',
    layout: {},
    paint: {
      'circle-radius': 6,
      'circle-color': '#999',
      'circle-pitch-alignment': 'map',
      'circle-opacity': 1,
      'circle-stroke-width': 1,
      'circle-stroke-color': mapColors.white
    }
  },
  nodes: {
    type: 'circle',
    layout: {},
    paint: {
      'circle-radius': 2,
      'circle-color': mapColors.baseColor,
      'circle-pitch-alignment': 'map',
      'circle-opacity': 0.5
    }
  },
  links: {
    type: 'line',
    layout: {
      'line-join': 'round',
      'line-cap': 'round'
    },
    paint: {
      'line-color': mapColors.baseColor,
      'line-width': 1,
      'line-opacity': 0.8
    }
  },
  signs_symbols: {
    type: 'symbol',
    layout: {
      'icon-image': ['get', 'resource_id'],
      'icon-rotate': ['get', 'angle'],
      'text-anchor': 'bottom',
      'icon-offset': iconOffsetValue,
      'icon-pitch-alignment': 'map',
      'icon-rotation-alignment': 'map',
      'icon-keep-upright': false,
      'icon-size': iconSizeValue2,
      'icon-ignore-placement': false,
      'text-allow-overlap': true,
      'icon-allow-overlap': true
    },
    paint: {
      'icon-opacity': 1
    }
  },
  signs_points: {
    type: 'circle',
    layout: {},
    paint: {
      'circle-radius': 2,
      'circle-color': mapColors.baseColor,
      'circle-pitch-alignment': 'map',
      'circle-opacity': 1,
      'circle-stroke-width': 1,
      'circle-stroke-color': mapColors.white
    }
  },
  signs_connections: {
    type: 'line',
    layout: {
      'line-join': 'round',
      'line-cap': 'round'
    },
    paint: {
      'line-color': mapColors.white,
      'line-width': 1,
      'line-dasharray': [2, 4]
    }
  },
  signs_editor_points: {
    type: 'circle',
    layout: {},
    paint: {
      'circle-radius': 2,
      'circle-color': mapColors.activeColor,
      'circle-pitch-alignment': 'map',
      'circle-opacity': 1,
      'circle-stroke-width': 1,
      'circle-stroke-color': mapColors.white
    }
  },
  signs_editor_connections: {
    type: 'line',
    layout: {
      'line-join': 'round',
      'line-cap': 'round'
    },
    paint: {
      'line-color': mapColors.white,
      'line-width': 1,
      'line-dasharray': [2, 4]
    }
  },
  events_points: {
    type: 'symbol',
    layout: {
      'icon-image': eventIconImage,
      'icon-pitch-alignment': 'map',
      'icon-rotation-alignment': 'map',
      'icon-size': iconSizeValue,
      'icon-ignore-placement': true,
      'text-allow-overlap': true,
      'icon-allow-overlap': true
    },
    paint: {
      'icon-opacity': 1
    }
  },
  events_lines: {
    type: 'line',
    layout: {
      'line-join': 'round',
      'line-cap': 'round'
    },
    paint: {
      'line-color': mapColors.ptEditRouteColor,
      'line-width': 4
    }
  },
  events_lines_icons: {
    type: 'symbol',
    layout: {
      'icon-image': eventIconImage,
      'symbol-placement': 'line-center',
      'icon-pitch-alignment': 'map',
      'icon-rotation-alignment': 'map',
      'icon-size': iconSizeValue,
      'icon-ignore-placement': true,
      'text-allow-overlap': true,
      'icon-allow-overlap': true
    },
    paint: {
      'icon-opacity': 1
    }
  },
  router_points: {
    type: 'circle',
    paint: {
      'circle-color': '#55b77e',
      'circle-radius': [
        'case',
        ['==', ['get', 'type'], 'point'],
        4,
        ['==', ['get', 'type'], 'hovered'],
        6,
        4
      ],
      'circle-stroke-color': '#fff',
      'circle-stroke-width': 2
    }
  },
  router_lines: {
    type: 'line',
    paint: {
      'line-color': '#55b77e',
      'line-width': 4
    },
    layout: {
      'line-join': 'round',
      'line-cap': 'round'
    }
  },
  router_arrows: {
    type: 'symbol',
    layout: {
      'symbol-placement': 'line',
      'text-field': '▶',
      'text-size': ['interpolate', ['linear'], ['zoom'], 12, 16, 22, 18],
      'symbol-spacing': ['interpolate', ['linear'], ['zoom'], 12, 32, 22, 45],
      'text-keep-upright': false
    },
    paint: {
      'text-color': '#55b77e',
      'text-halo-color': '#fff',
      'text-halo-width': 1.3
    }
  }
}
